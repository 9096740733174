import { QueryClientProvider, QueryClient } from 'react-query'

import { AuthProvider } from 'contexts/auth-context'
import { BrowserRouter } from 'react-router-dom'
import AppRoutes from 'routes'
import { RoutesListener } from 'routes/route-listener'
const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <RoutesListener />
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
