export const events = {
  SOCIAL_CLICK: (social: string) => `Clique social: ${social}`,
  CHANGE_ACTIVE_PRODUCT: 'Alterou produto ativo',
  OPEN_SIDE_MENU: 'Abrir menu',
  COPY_INVOICE_PAYMENT_CODE: 'Copiar linha digitável',
  SIGN_IN: 'SignIn',
  SIGN_OUT: 'SignOut',
  FAQ: 'Dúvidas frequentes',
  FILTER_INSTALLMENTS: 'Filtrar parcelas',
  DOWNLOAD_FINANCIAL_STATEMENT: 'Download extrato financeiro',
  VISIT_BLOG: 'Blog da Vinx',
  CLICK_INVOICES: 'Clicou Boletos',
  VIEW_PRODUCT: 'Clique no produto',
  RECOVER_PASSWORD: 'Recuperar senha'
}
