import { useState } from 'react'
import { useQuery } from 'react-query'
import { TechnicalSupportDto } from 'services/http/dtos/technical-support.dto'
import http from 'services/http'

const fetchTechnicalSupportCount = async () => {
  const { data } = await http.get<{ total: number }>('/cliente/pedidos_assistencia_tecnica/total')
  return data?.total
}

export const useTechnicalSupportCount = () => useQuery(['technical-support-count'], () => fetchTechnicalSupportCount())

const fetchTechnicalSupport = async () => {
  const { data, ok } = await http.get<TechnicalSupportDto[]>('/cliente/pedidos_assistencia_tecnica')
  return ok ? data : undefined
}

export const useTechnicalSupport = () => useQuery(['technical-support'], () => fetchTechnicalSupport())

const fetchTecnicalSupport = async () => {
  const { data } = await http.get<TechnicalSupportDto[]>('/cliente/pedidos_assistencia_tecnica')
  return data
}

export const useTecnicalSupport = () => useQuery('tecnical-support', fetchTecnicalSupport)

export const useNewTechnicalSupport = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<string>()

  const createNew = async (message: string, codigoVenda: number, photos: File[]) => {
    setIsLoading(true)
    const data = new FormData()

    data.append('mensagem', message)
    data.append('codigoVenda', codigoVenda.toString())
    data.append('idCanalAtendimento', '15')

    photos.forEach((file) => data.append('files', file))

    const response = await http.post<string>('/cliente/inserir_caso_assistencia_tecnica', data)
    setData(response.data)
    setIsLoading(false)
  }

  return { createNew, isLoading, data }
}
