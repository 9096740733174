import * as cpf from '@fnando/cpf'
import * as cnpj from '@fnando/cnpj'

export const isCpfCnpjValid = (value: string) => {
  if (cleanCpfCnpj(value).length === 11) {
    return cpf.isValid(value)
  }
  return cnpj.isValid(value)
}
export const formatCpfCnpj = (value: string) =>
  cleanCpfCnpj(value).length === 11 ? cpf.format(value) : cnpj.format(value)
export const cleanCpfCnpj = cnpj.strip
