import { Box, Button, Heading, Stack, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'
import { emptyFn } from 'utils/empty-fn'

export type InvoiceDetailsProps = {
  title: string
  apartment: string
  value: string
  dueDate: string
  paymentCode: string
  onDownloadClick: () => void
  onPrintClick: () => void
  onCopyPaymentCodeClick: () => void
}

export const InvoiceDetails = ({
  title,
  apartment,
  value,
  dueDate,
  paymentCode,
  onDownloadClick = emptyFn,
  onPrintClick = emptyFn,
  onCopyPaymentCodeClick = emptyFn
}: InvoiceDetailsProps) => {
  const { isMobile } = useDeviceScreenSize()

  return (
    <Box px="40px" py="30px" borderRadius="4px" bg="dark.300" h={isMobile ? 'auto' : '330px'}>
      <Heading variant="h5-bold">{`${title} - ${apartment}`}</Heading>
      <Wrap spacing={isMobile ? '30px' : '100px'} pt="25px">
        <WrapItem>
          <Stack>
            <Text color="dark.800">Valor</Text>
            <Text variant="bold">{value}</Text>
          </Stack>
        </WrapItem>
        {/* <WrapItem>
          <Stack>
            <Text color="dark.800">Tipo</Text>
            <Text variant="bold">{type}</Text>
          </Stack>
        </WrapItem> */}
        <WrapItem>
          <Stack>
            <Text color="dark.800">Vencimento</Text>
            <Text variant="bold">{dueDate}</Text>
          </Stack>
        </WrapItem>
      </Wrap>
      <Stack mt="20px">
        <Text color="dark.800">Linha digitável</Text>
        <Text variant="bold">{paymentCode}</Text>
      </Stack>
      <Stack mt="50px" direction={isMobile ? 'column' : 'row'}>
        <Button w={isMobile ? 'full' : 'auto'} onClick={onDownloadClick}>
          Download do boleto
        </Button>
        <Box>
          <Button w={isMobile ? 'full' : 'auto'} onClick={onCopyPaymentCodeClick} variant="outline">
            Copiar linha digitável
          </Button>
        </Box>
        <Box>
          <Button w={isMobile ? 'full' : 'auto'} onClick={onPrintClick} variant="outline">
            Imprimir
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
