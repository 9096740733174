export default {
  baseStyle: {
    color: 'primary.dark',
    fontWeight: 300,
    _hover: { color: 'primary.brand', textDecoration: 'none' }
  },
  variants: {
    white: {
      fontWeight: 300,
      color: 'whiteAlpha.700'
    },
    dark: {
      fontWeight: 300,
      color: 'dark.900'
    }
  }
}
