import { Flex, Grid, useBreakpointValue, SkeletonText, SkeletonCircle } from '@chakra-ui/react'

export const StatisticSkeleton = () => {
  const chartSize = useBreakpointValue({ base: '30px', lg: '40px' })

  return (
    <Grid
      templateAreas={`'title chart' 'label chart'`}
      bg="dark.300"
      borderRadius="full"
      h={{ base: '80px', lg: '104px' }}
      alignItems="stretch"
      overflow="hidden"
      px={8}
    >
      <Flex gridArea="title" alignItems="flex-end" justifyContent="flex-start">
        <SkeletonText width="full" />
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end" gridArea="chart">
        <SkeletonCircle size={chartSize} />
      </Flex>
    </Grid>
  )
}
