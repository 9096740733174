import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { mixpanel } from 'services/mixpanel'

export const RoutesListener = () => {
  const location = useLocation()

  useEffect(() => {
    mixpanel.track(location.pathname, { page_view: true })
  }, [location])

  return <></>
}
