import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Center,
  ModalFooter,
  Button,
  Text,
  Image
} from '@chakra-ui/react'

import SuccessBanner from 'assets/images/image_support_success_banner.png'
import SuccessBanner2x from 'assets/images/image_support_success_banner@2x.png'

type SuccessModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const SuccessModal = ({ isOpen, onClose }: SuccessModalProps) => {
  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Enviado com sucesso!</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Center py="30px">
            <Image src={SuccessBanner} srcSet={`${SuccessBanner} 1x, ${SuccessBanner2x} 2x`} boxSize="150px" />
          </Center>
          <Text>
            <strong>Agora é com a gente!</strong> <br />
            <br />A sua solicitação de Assistência Técnica já foi recebida com sucesso! Estamos avaliando para ver se
            está tudo certo. Em até 5 dias úteis entraremos em contato com você, para tomar todas as medidas e atender a
            sua solicitação.
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} w="130px">
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
