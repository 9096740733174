import { Box } from '@chakra-ui/react'
import { Chart, ChartWrapperOptions } from 'react-google-charts'
import { memo, useEffect, useState } from 'react'
import equal from 'react-fast-compare'

export interface PaymentSummaryChartData {
  id: string
  label: string
  value: number
  color: string
}

export interface PaymentSummaryChartProps {
  data: PaymentSummaryChartData[]
}

export const options: ChartWrapperOptions['options'] = {
  title: '',
  pieHole: 0.4,
  is3D: false,
  colors: [],
  fontName: 'Mitr',
  backgroundColor: 'transparent',
  legend: {
    position: 'labeled'
  }
}

export const PaymentSummaryChart = memo(({ data }: PaymentSummaryChartProps) => {
  const [chartData, setChartData] = useState<(string | number)[][]>()

  useEffect(() => {
    const labels = ['label', 'value']
    const colors = data.map((item) => item.color)
    const values = data.map((item) => [item.label, item.value])
    setChartData([labels, ...values])
    options.colors = colors
  }, [data])

  return (
    <Box bg="dark.300" borderRadius="full" h={227} px="30px" w="full" flex={1}>
      <Chart
        chartType="PieChart"
        width="100%"
        height="100%"
        data={chartData}
        options={options}
        style={{ fontFamily: 'Mitr' }}
      />
    </Box>
  )
}, equal)

PaymentSummaryChart.displayName = 'PaymentSummaryChart'
