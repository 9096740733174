import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

export const authInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        localStorage.clear()
        window.location.replace('/login')
      }

      return error
    }
  )
}
