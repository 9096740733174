import { ProductDto } from 'services/http/dtos/user-info.dto'
import create from 'zustand'

type State = {
  activeProduct?: ProductDto
  products: ProductDto[]
  setProdutcs: (products: ProductDto[]) => void
  setActiveProduct: (product: ProductDto) => void
}

export const useApplicationStore = create<State>((set) => ({
  products: [],
  setProdutcs: (products: ProductDto[]) => {
    const activeProduct = products[0]
    set({ activeProduct, products })
  },
  setActiveProduct: (product: ProductDto) => set({ activeProduct: product })
}))
