import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Center,
  Flex,
  Stack,
  useBreakpointValue,
  Text
} from '@chakra-ui/react'
import { FinancialSummaryDto } from 'services/http/dtos/financial-summary.dto'
import { Statistic, StatisticSkeleton } from 'components/statistic'
import { formatMoney } from 'utils/format-money'
import { PaymentSummaryChart, PaymentSummaryChartSkeleton } from 'components/payment-summary-chart'
import { RenderWhen } from 'components/render-when'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'

const buildChartData = (data?: FinancialSummaryDto) => [
  {
    id: 'a_vencer',
    label: 'A vencer',
    value: data?.totalDebt || 0,
    color: '#2862B9'
  },
  {
    id: 'em_atraso',
    label: 'Em atraso',
    value: data?.totalOverdue || 0,
    color: '#F5B50A'
  },
  {
    id: 'pago',
    label: 'Pago',
    value: data?.totalPaid || 0,
    color: '#348D6A'
  }
]

export type FinancialSummaryProps = {
  data?: FinancialSummaryDto
  hasError?: boolean
}

export const FinancialSummary = ({ data, hasError }: FinancialSummaryProps) => {
  const { isMobile, isDesktop } = useDeviceScreenSize()
  const itemsSpacing = useBreakpointValue({ base: '10px', md: '30px' })

  if (hasError) {
    return (
      <Center>
        <Alert
          status="warning"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
          variant="top-accent"
        >
          <AlertIcon boxSize="30px" />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Opa!
          </AlertTitle>
          <AlertDescription maxWidth="lg">
            <Text>
              Tivemos algum problema ao tentar exibir o seu Resumo Financeiro, mas não se preocupe, estamos trabalhando
              e em breve tudo deve estar funcionando!
            </Text>
          </AlertDescription>
        </Alert>
      </Center>
    )
  }

  return (
    <>
      <Stack direction={{ base: 'column', xl: 'row' }} spacing={itemsSpacing}>
        <Stack flex={1} spacing={itemsSpacing} direction={isMobile ? 'column' : 'row'}>
          <Stack flex={1} spacing={itemsSpacing}>
            <RenderWhen
              when={!!data}
              fallback={StatisticSkeleton}
              render={() => (
                <Statistic
                  color="#2862B9"
                  title="A vencer"
                  formatter={formatMoney}
                  value={data.totalDebt}
                  progressValue={data.percentDebt}
                />
              )}
            />

            <RenderWhen
              when={!!data}
              fallback={StatisticSkeleton}
              render={() => (
                <Statistic
                  color="#F5B50A"
                  title="Em atraso"
                  formatter={formatMoney}
                  value={data.totalOverdue}
                  progressValue={data.percentOverdue}
                />
              )}
            />
          </Stack>
          <Stack flex={1} spacing={itemsSpacing}>
            <RenderWhen
              when={!!data}
              fallback={StatisticSkeleton}
              render={() => (
                <Statistic
                  color="#348D6A"
                  title="Pago"
                  formatter={formatMoney}
                  value={data.totalPaid}
                  progressValue={data.percentPaid}
                />
              )}
            />

            <RenderWhen
              when={!!data}
              fallback={StatisticSkeleton}
              render={() => (
                <Statistic
                  color="gray.50"
                  title="Total"
                  formatter={formatMoney}
                  value={data.totalPrice}
                  showChart={false}
                  progressValue={0}
                />
              )}
            />
          </Stack>
        </Stack>
        {isDesktop && (
          <Flex flex={1}>
            <RenderWhen
              when={!!data}
              fallback={PaymentSummaryChartSkeleton}
              render={() => <PaymentSummaryChart data={buildChartData(data!)} />}
            />
          </Flex>
        )}
      </Stack>
    </>
  )
}
