import {
  Box,
  VStack,
  Text,
  Heading,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Accordion,
  AccordionIcon,
  Image,
  Stack
} from '@chakra-ui/react'

import { frequentQuestions } from 'data/faq'
import { useResponsiveImage, createSet } from 'hooks/use-responsive-image'

import ImageBanner from 'assets/images/faq_banner_image.png'
import ImageBanner2x from 'assets/images/faq_banner_image@2x.png'
import ImageBannerSmall from 'assets/images/faq_banner_small_image.png'
import ImageBannerSmall2x from 'assets/images/faq_banner_small_image@2x.png'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'
import { useTitle } from 'routes/use-title'

export const Faq = () => {
  useTitle('Dúvidas frequentes')
  const { isMobile, isDesktop } = useDeviceScreenSize()
  const imageBanner = useResponsiveImage({
    mobile: { ...createSet(ImageBannerSmall, ImageBannerSmall2x), boxSize: 190 },
    desktop: { ...createSet(ImageBanner, ImageBanner2x), boxSize: 290 }
  })

  return (
    <>
      <Stack
        justify="flex-end"
        align="center"
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? '30px' : 0}
        mb={isMobile ? '60px' : 0}
      >
        {isMobile && (
          <Image src={imageBanner.src} srcSet={imageBanner.srcSet} w={`${imageBanner.boxSize}px`} objectFit="contain" />
        )}
        <VStack align={isMobile ? 'center' : 'flex-end'} pr={isMobile ? 0 : '30px'} spacing={isMobile ? '30px' : 0}>
          <Heading variant={isMobile ? 'h3-bold' : 'h2-bold'}>Pintou uma dúvida?</Heading>
          <Text maxW="680px" align={isMobile ? 'justify' : 'right'}>
            Não se preocupe, estamos aqui pra te ajudar! Separamos algumas das perguntas mais frequêntes de nossos
            clientes. Caso isso não seja suficiente você pode falar com a gente no número (11) 4118-9156 ou (11)
            4118-9157.
          </Text>
        </VStack>
        {isDesktop && (
          <Image src={imageBanner.src} srcSet={imageBanner.srcSet} w={`${imageBanner.boxSize}px`} objectFit="contain" />
        )}
      </Stack>
      {frequentQuestions.map(({ title, questions }) => (
        <Box key={title}>
          <Heading variant="h4-bold">{title}</Heading>
          <Accordion allowToggle borderColor="dark.300" mx={isMobile ? '-30px' : 0} py="20px">
            {questions.map((question) => (
              <AccordionItem key={question.question}>
                <Heading variant="h2">
                  <AccordionButton py="10px">
                    <Box flex="1" textAlign="left">
                      <Text>{question.question}</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </Heading>
                <AccordionPanel pb={4}>
                  <Text lineHeight="24px" whiteSpace="pre-wrap">
                    {question.answer}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      ))}
    </>
  )
}
