import { useBreakpointValue } from '@chakra-ui/react'

export type ResponsiveImageSet = {
  src: string
  srcSet: string
}

export type ResponsiveImage = ResponsiveImageSet & {
  boxSize: number
}

export type ResponsiveImageProps = {
  mobile: ResponsiveImage
  desktop: ResponsiveImage
}

export const useResponsiveImage = (props: ResponsiveImageProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false })
  const image = isMobile ? props.mobile : props.desktop
  return image
}

export const createSet = (src: string, src2x: string): ResponsiveImageSet => ({ src, srcSet: `${src} 1x, ${src2x} 2x` })
