import {
  BackgroundProps,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Image,
  Link,
  List,
  ListItem
} from '@chakra-ui/react'

import logoVinxVerde from 'assets/images/vinx_logo_verde.png'

import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { IconType } from 'react-icons'
import { NavigationLink } from 'components/navigation-link'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'

export type DrawerMenuProps = {
  isOpen: boolean
  onClose: () => void
  onLogoutClick?: () => void
  activeProductName: string
  onActiveProductClick?: () => void
  showProductsMenu: boolean
}

export const DrawerMenu = ({
  isOpen,
  onClose,
  onLogoutClick,
  activeProductName,
  onActiveProductClick,
  showProductsMenu
}: DrawerMenuProps) => {
  const { isMobile } = useDeviceScreenSize()

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size={isMobile ? 'full' : 'xs'}>
      <DrawerOverlay />
      <DrawerContent bg="dark.900" px="10px">
        <DrawerCloseButton color="primary.brand" bg="dark.800" borderRadius="full" w="50px" h="50px" />
        <DrawerHeader>
          <Image src={logoVinxVerde} w="70px" />
        </DrawerHeader>

        <DrawerBody>
          <List spacing="25px" pt="40px">
            {showProductsMenu && (
              <>
                <Box>
                  <Button variant="outline" onClick={onActiveProductClick}>
                    {activeProductName}
                  </Button>
                </Box>
                <Divider borderColor="dark.800" />
              </>
            )}
            <ListItem>
              <Link variant="white" fontSize="19px" as={NavigationLink} to="/" onClick={onClose}>
                Início
              </Link>
            </ListItem>
            <ListItem>
              <Link variant="white" fontSize="19px" as={NavigationLink} to="/financeiro" onClick={onClose}>
                Fluxo Financeiro
              </Link>
            </ListItem>
            <ListItem>
              <Link variant="white" fontSize="19px" as={NavigationLink} to="/assistencia-tecnica" onClick={onClose}>
                Assistência técnica
              </Link>
            </ListItem>
            <ListItem>
              <Link variant="white" fontSize="19px" as={NavigationLink} to="/faq" onClick={onClose}>
                Dúvidas frequentes
              </Link>
            </ListItem>
            <ListItem>
              <Link
                variant="white"
                fontSize="19px"
                href="https://vinx.com.br/empreendimentos"
                isExternal
                onClick={onClose}
              >
                Empreendimentos
              </Link>
            </ListItem>
            {/* <ListItem>
            <Link variant="white" fontSize="19px" as={RouterLink} to="http://vinxconstrutora.com.br/#" isExternal>
              Grupo Vinx
            </Link>
          </ListItem> */}
            {/* <ListItem>
            <Link variant="white" fontSize="19px">
              Casa Verde e Amarela
            </Link>
          </ListItem> */}
            {/* <ListItem>
            <Link variant="white" fontSize="19px" as={RouterLink} to="http://vinxconstrutora.com.br/#" isExternal>
              Venda seu terreno
            </Link>
          </ListItem> */}
            <ListItem>
              <Link variant="white" fontSize="19px" href="https://vinx.com.br/blog" isExternal>
                Blog da Vinx
              </Link>
            </ListItem>
            <Divider borderColor="dark.800" />
            <ListItem>
              <Link color="red.600" _hover={{ color: 'red.500' }} fontWeight={400} onClick={onLogoutClick}>
                Sair
              </Link>
            </ListItem>
          </List>
        </DrawerBody>

        <DrawerFooter>
          <HStack spacing={4} justify="center" flex={1} pb="16px">
            <RoundedLinkButton icon={FaFacebookF} href="https://www.facebook.com/vinxconstrutora/" />
            <RoundedLinkButton icon={FaInstagram} href="https://www.instagram.com/vinxconstrutora/" />
            <RoundedLinkButton icon={FaLinkedinIn} href="https://www.linkedin.com/company/vinxconstrutora" />
            <RoundedLinkButton icon={FaTwitter} href="https://twitter.com/vinxConstrutora" />
          </HStack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

type RoundedLinkButtonProps = {
  icon: IconType
  href?: string
  bgColor?: BackgroundProps['bg']
}

const RoundedLinkButton = ({ href, icon, bgColor = 'currentcolor' }: RoundedLinkButtonProps) => (
  <Box role="group">
    <Link
      bg={bgColor}
      href={`${href}`}
      isExternal
      w="45px"
      h="45px"
      borderRadius="full"
      aria-label="Instagram"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderWidth="1px"
      borderStyle="solid"
      borderColor={bgColor ?? 'dark.500'}
      _groupHover={{
        borderColor: 'primary.brand'
      }}
    >
      <Icon
        boxSize="20px"
        as={icon}
        color={bgColor ? 'white' : 'whiteAlpha.700'}
        _groupHover={{ color: bgColor ? 'white' : 'primary.brand' }}
      />
    </Link>
  </Box>
)
