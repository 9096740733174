import { useState } from 'react'
import { useBreakpointValue, Stack, Heading, Text, Box, useDisclosure, SimpleGrid, Link } from '@chakra-ui/react'

import { ShotcutBox } from 'components/shotcut-box'

import { useApplicationStore } from 'stores/application-store'
import { useFinancialSummary } from 'hooks/use-financial-summary'
import { formatMoney } from 'utils/format-money'
import { useAvailableBills } from 'hooks/use-available-bills'
import { BoletoClienteType } from 'services/http/dtos/available-bills.dto'

import { InvoiceDetails, InvoiceDetailsEmpty, InvoiceDetailsSkeleton } from 'components/invoice-details'
import { InvoiceBarcodeDialog } from 'components/invoice-barcode-dialog'
import { LoadingDialog } from 'components/loading-dialog'

import { useGetInvoicePDFBase64 } from 'hooks/use-bill-pdf'
import { downloadPDF } from 'utils/download-pdf'
import { formatDate } from 'utils/format-date'
import { viewPDF } from 'utils/view-pdf'
import { FinancialSummary } from 'components/financial-summary'
import { useGetProductManual } from 'hooks/use-user-manual'
import { openInNewTab } from 'utils/open-new-tab'
import { useNavigate } from 'react-router-dom'
import { ModalDialog } from 'components/modal-dialog'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'
import { firstName } from 'utils/first-name'
import { useAuth } from 'hooks/use-auth'
import { useTitle } from 'routes/use-title'

export const Dashboard = () => {
  useTitle('Início')
  const sectionGap = useBreakpointValue({ base: '60px', sm: '80px', lg: '100px' })
  const itemsSpacing = useBreakpointValue({ base: '10px', md: '30px' })
  const activeProduct = useApplicationStore((state) => state.activeProduct!)
  const userProfile = useAuth().user.profile
  const { data: financialSummary, isError: financialSummaryError } = useFinancialSummary(activeProduct.codigoVenda)
  const { data: availableBills } = useAvailableBills()
  const { base64InvoicePDF } = useGetInvoicePDFBase64()
  const [loadingDialogOpen, setLoadingDialogOpen] = useState(false)
  const [billCode, setBillCode] = useState('')
  const { isOpen: isBarcodeDialogOpen, onOpen: onBarcodeDialogOpen, onClose: onBarcodeDialogClose } = useDisclosure()
  const {
    isOpen: isNoInvoiceDialogOpen,
    onOpen: onNoInvoiceDialogOpen,
    onClose: onNoInvoiceDialogClose
  } = useDisclosure()
  const { getManual, isLoading: isFetchingManual } = useGetProductManual()

  const navigate = useNavigate()
  const { isMobile } = useDeviceScreenSize()

  const onGetManual = async () => {
    const data = await getManual(activeProduct.codigoProduto)
    if (data) {
      openInNewTab(data)
    } else {
      navigate('/manual-ausente')
    }
  }

  const onGetInvoiceAsPDF = async (invoice: BoletoClienteType) => {
    setLoadingDialogOpen(true)
    const invoiceName = generateInvoiceFileName(invoice)
    const invoicePDF = await base64InvoicePDF(invoice.codBanco, invoice.seuNumero)

    if (!invoicePDF) {
      setLoadingDialogOpen(false)
      return
    }

    downloadPDF(invoicePDF, invoiceName)
    setLoadingDialogOpen(false)
  }

  const onPrintInvoice = async (invoice: BoletoClienteType) => {
    setLoadingDialogOpen(true)
    const invoicePDF = await base64InvoicePDF(invoice.codBanco, invoice.seuNumero)

    if (!invoicePDF) {
      setLoadingDialogOpen(false)
      return
    }

    viewPDF(invoicePDF)
    setLoadingDialogOpen(false)
  }

  const onCopyInvoiceBarCode = (invoice: BoletoClienteType) => {
    setBillCode(invoice.linhaDigitavel)
    onBarcodeDialogOpen()
  }

  const generateInvoiceFileName = (invoice: BoletoClienteType) => `${invoice.vencimento.split('T')[0]}.pdf`

  const getInvoice = () => {
    if (availableBills?.length === 0) {
      onNoInvoiceDialogOpen()
      return
    }

    document.location.href = '#invoices-anchor'
  }

  return (
    <>
      <LoadingDialog title="Preparando o boleto" isOpen={loadingDialogOpen} />
      <InvoiceBarcodeDialog code={billCode} isOpen={isBarcodeDialogOpen} onClose={() => onBarcodeDialogClose()} />
      <ModalDialog title="Segunda via de boleto" isOpen={isNoInvoiceDialogOpen} onClose={onNoInvoiceDialogClose}>
        <Text>
          Você ainda não tem boletos disponíveis. <br />
          <br />
          Caso queira solicitar adiantamento de parcelas, entre em contato com o nosso Atendimento ao Cliente:
          <br />{' '}
          <Text variant="bold" display="inline">
            Telefone:&nbsp;
          </Text>
          (11) 3676-1157
          <br />
          <Text variant="bold" display="inline">
            E-mail:&nbsp;
          </Text>
          &nbsp; atendimento@vinx.com.br
        </Text>
      </ModalDialog>

      <Stack>
        <Heading variant={isMobile ? 'h3-bold' : 'h2-bold'}>Olá {firstName(userProfile?.nome)}!</Heading>
        <Text>Colocamos as opções mais acessadas bem pertinho de você.</Text>
        <SimpleGrid pt="30px" columns={{ base: 1, md: 2, xl: 4 }} spacing={itemsSpacing}>
          <ShotcutBox
            buttonTitle="Segunda via de boleto"
            buttonBadge={availableBills?.length}
            buttonWidth="210px"
            background="payment"
            onLinkClick={getInvoice}
            isButtonActive
          />
          <ShotcutBox
            buttonTitle="Estágio da obra"
            buttonWidth="210px"
            background="stage"
            onLinkClick={() => openInNewTab(activeProduct.urlSite)}
          />
          <ShotcutBox
            buttonTitle="Assistência técnica"
            buttonWidth="210px"
            background="support"
            onLinkClick={() => navigate('/assistencia-tecnica')}
          />
          <ShotcutBox
            buttonTitle="Manual do proprietário"
            buttonWidth="210px"
            background="manual"
            onLinkClick={onGetManual}
            isLoading={isFetchingManual}
          />
        </SimpleGrid>
        <Heading variant={isMobile ? 'h3-bold' : 'h2-bold'} pt={sectionGap}>
          Resumo financeiro
        </Heading>
        <Text>Seus boletos e um resumo financeiro do seu contrato ficam aqui.</Text>
        <Box h="15px" />

        <FinancialSummary data={financialSummary} hasError={financialSummaryError} />
        <Heading variant={isMobile ? 'h3-bold' : 'h2-bold'} pt={sectionGap} id="invoices-anchor">
          Boletos disponíveis
        </Heading>
        <Text>Quando seus boletos estiverem disponíveis, eles aparecerão aqui pra você.</Text>
        <Box pt="30px">
          {availableBills ? (
            availableBills.length > 0 ? (
              availableBills.map((invoice) => (
                <Box key={invoice.linhaDigitavel} mb="25px">
                  <InvoiceDetails
                    title={invoice.produto}
                    apartment={invoice.unidade}
                    value={formatMoney(invoice.valor)}
                    dueDate={formatDate(Date.parse(invoice.vencimento))}
                    paymentCode={invoice.linhaDigitavel}
                    onPrintClick={() => onPrintInvoice(invoice)}
                    onCopyPaymentCodeClick={() => onCopyInvoiceBarCode(invoice)}
                    onDownloadClick={() => onGetInvoiceAsPDF(invoice)}
                  />
                </Box>
              ))
            ) : (
              <InvoiceDetailsEmpty />
            )
          ) : (
            <InvoiceDetailsSkeleton />
          )}
        </Box>
        <Text fontSize="xs" color="gray.500">
          Consulto a evolução do INCC:{' '}
          <Link isExternal href="https://www.debit.com.br/tabelas/tabela-completa.php?indice=incc">
            https://www.debit.com.br/tabelas/tabela-completa.php?indice=incc
          </Link>
        </Text>
      </Stack>
    </>
  )
}
