import { Box, Heading, VStack, Image, Text, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import UnavailableManualImage from 'assets/images/unavailable_manual@2x.png'

export const UnavailableManual = () => (
  <VStack maxW="1024px" m="0 auto" pt="30px" spacing="30px">
    <Heading mb={8}>O seu manual ainda não está pronto</Heading>
    <Image src={UnavailableManualImage} w="440px" />
    <Box>
      <Text lineHeight="28px">
        Que pena, o seu manual ainda não está pronto!
        <br />
        <br />
        <p>
          Mas não se preocupe, o Manual do Cliente Vinx é liberado junto com as chaves do seu novo apartamento. Assim
          que estiver tudo pronto, a gente manda o Manual por e-mail pra você, e você também recebe uma versão impressa
          junto das duas chaves.
        </p>
      </Text>
    </Box>
    <Link pt="60px" as={RouterLink} to="/">
      Voltar ao início
    </Link>
  </VStack>
)
