import { Box, Alert, AlertIcon, Heading, useToken, HStack, Center, Text, Spinner } from '@chakra-ui/react'
import { formatDate } from 'utils/format-date'
import { SupportPageHeader } from './header'
import { PhoneIcon, EmailIcon } from '@chakra-ui/icons'
import { useTechnicalSupport } from 'hooks/use-supports'

export function SupportInfo() {
  const { data: supports, isLoading: isSupportsLoading } = useTechnicalSupport()

  if (isSupportsLoading) {
    return (
      <Center flex={1}>
        <Spinner color="primary.brand" />
      </Center>
    )
  }

  return (
    <Box>
      <SupportPageHeader />
      <Alert status="info" my={6} colorScheme="blackAlpha">
        <AlertIcon />
        Você já tem um pedido de assistência em andamento.
      </Alert>
      <Box bg="silver.300" p={4} rounded={8}>
        <Heading as="h2" size={'md'}>
          Detalhes da solicitação em aberto:
        </Heading>
        <Box h={1} />
        <Text fontSize="sm" fontWeight={300}>
          Protocolo:
        </Text>
        <Text fontSize="md">{supports[0].id}</Text>
        <Box h="px" bg="gray.200" my={1} />
        <Text fontSize="sm" fontWeight={300}>
          Data da solicitação:
        </Text>
        <Text fontSize="md">{formatDate(Date.parse(supports[0].dataAbertura))}</Text>
        <Box h="px" bg="gray.200" my={1} />
        <Text fontSize="sm" fontWeight={300}>
          Unidade:
        </Text>
        <Text fontSize="md">{supports[0].unidade}</Text>
        <Box h="px" bg="gray.200" my={1} />

        <Box h={4} />
        <HelpFooter />
      </Box>
    </Box>
  )
}

const HelpFooter = () => {
  const dark900 = useToken('colors', 'dark.900')

  return (
    <>
      <Text fontWeight={300}>Caso precise de ajuda, entre em contato pelos nossos canais de atendimento:</Text>
      <HStack pt={8} spacing={4}>
        <Center
          flex={1}
          h={45}
          rounded="full"
          bg="white"
          _hover={{ cursor: 'pointer' }}
          onClick={() => window.open('tel:1136761157')}
        >
          <HStack spacing={2}>
            <PhoneIcon color={dark900} boxSize={18} />
            <Text>(11) 3676-1157</Text>
          </HStack>
        </Center>

        <Center
          flex={1}
          h={45}
          rounded="full"
          bg="white"
          _hover={{ cursor: 'pointer' }}
          onClick={() => window.open('mailto:atendimento@vinx.com.br')}
        >
          <HStack spacing={2}>
            <EmailIcon color={dark900} boxSize={18} />
            <Text>E-mail</Text>
          </HStack>
        </Center>
      </HStack>
    </>
  )
}
