import { useState } from 'react'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  Input,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'

import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import http from 'services/http'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'hooks/use-auth'
import { ApiError } from 'services/http/dtos/error.dto'
import { InputFrame } from 'components/input-frame'
import { useTitle } from 'routes/use-title'
import { cleanCpfCnpj, isCpfCnpjValid, formatCpfCnpj } from 'utils/cpf-cnpj'
import { isValid } from 'date-fns'

interface IFormInputs {
  cpf: string
  dataNascimento: Date
  login: string
  email: string
  senha: string
}

const schema = yup.object().shape({
  cpf: yup
    .string()
    .test(
      'cpf',
      (doc) => `${cleanCpfCnpj(doc.value.toString()).length === 11 ? 'CPF' : 'CNPJ'} inválido`,
      (doc) => isCpfCnpjValid(doc)
    )
    .label('CPF/CNPJ'),
  dataNascimento: yup
    .string()
    .label('Data de nascimento')
    .test('date', 'Data inválida!', (value) => isValid(new Date(value))),
  login: yup.string().min(9).max(15).label('Login'),
  email: yup.string().email().label('E-mail'),
  senha: yup.string().length(6).label('Senha')
})

export const RegisterForm = () => {
  useTitle('Registrar usuário')
  const [isLoading, setIsLoading] = useState(false)
  const { Login } = useAuth()
  const [registerError, setRegisterError] = useState<ApiError>()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  })

  const navigate = useNavigate()

  const submitForm = async (value: IFormInputs) => {
    setIsLoading(true)
    const userData = { ...value, cpf: cleanCpfCnpj(value.cpf), dataNascimento: new Date(value.dataNascimento) }
    const response = await http.post<string, ApiError>('/auth/criar_credenciais', { ...userData })

    if (response.ok) {
      await Login(value.login, value.senha)
      navigate('/')
    }

    if (response.problem) {
      setRegisterError(response.data as ApiError)
      setIsLoading(false)
    }
  }

  return (
    <Box w="80%">
      <Text fontSize="2xl" mb={4}>
        Criar acesso
      </Text>
      <form onSubmit={handleSubmit(submitForm)}>
        {registerError && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            <AlertDescription>{registerError.message}</AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" onClick={() => setRegisterError(undefined)} />
          </Alert>
        )}
        <Stack>
          <FormControl isRequired isInvalid={!!errors.cpf}>
            <InputFrame label="CPF/CNPJ">
              <Input
                {...register('cpf')}
                type="text"
                onBlur={(e) => (e.target.value = formatCpfCnpj(e.target.value))}
              />
            </InputFrame>
            <FormErrorMessage>{errors.cpf?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.dataNascimento}>
            <InputFrame label="Data de nascimento">
              <Input {...register('dataNascimento')} type="date" id="dataNascimento" name="dataNascimento" />
            </InputFrame>
            <FormErrorMessage>{errors.dataNascimento?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.login}>
            <InputFrame label="Login">
              <Input {...register('login')} id="login" name="login" />
            </InputFrame>
            <FormErrorMessage>{errors.login?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.email}>
            <InputFrame label="E-mail">
              <Input {...register('email')} id="email" name="email" type="email" />
            </InputFrame>
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.senha}>
            <InputFrame label="Senha">
              <Input {...register('senha')} id="senha" name="senha" type="password" />
            </InputFrame>
            <FormErrorMessage>{errors.senha?.message}</FormErrorMessage>
          </FormControl>
          <Box pt={15}>
            <Button type="submit" color="gray.900" w={200} isLoading={isLoading}>
              Criar acesso
            </Button>
          </Box>
          <Stack pt={20}>
            <Link variant="dark" as={RouterLink} to="/login/forgot-password" fontSize="sm" pb={5}>
              Esqueci minha senha
            </Link>
            <Link variant="dark" as={RouterLink} to="/login" fontSize="sm">
              Já tenho cadastro, <b>Entrar</b>
            </Link>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}
