export default {
  variants: {
    h1: {
      fontSize: 45,
      fontWeight: 200,
      lineHieght: 50
    },
    'h1-bold': {
      fontSize: 45,
      fontWeight: 400,
      lineHieght: 50
    },
    h2: {
      fontSize: 38,
      fontWeight: 200,
      lineHieght: 48
    },
    'h2-bold': {
      fontSize: 38,
      fontWeight: 400,
      lineHieght: 48
    },
    h3: {
      fontSize: 30,
      fontWeight: 200,
      lineHieght: 40
    },
    'h3-bold': {
      fontSize: 30,
      fontWeight: 400,
      lineHieght: 40
    },
    h4: {
      fontSize: 22,
      fontWeight: 200,
      lineHieght: 35
    },
    'h4-bold': {
      fontSize: 22,
      fontWeight: 400,
      lineHieght: 35
    },
    h5: {
      fontSize: 19,
      fontWeight: 200,
      lineHieght: 35
    },
    'h5-bold': {
      fontSize: 19,
      fontWeight: 400,
      lineHieght: 35
    }
  }
}
