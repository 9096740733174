export default {
  baseStyle: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '24px',
    color: 'black'
  },
  variants: {
    bold: {
      fontWeight: 500
    },
    'semi-bold': {
      fontWeight: 400
    },
    medium: {
      fontSize: '14px'
    },
    'medium-bold': {
      fontSize: '14px',
      fontWeight: 500
    },
    small: {
      fontSize: '11px',
      lineHeight: '16px'
    },
    'extra-small': {
      fontSize: '9px',
      lineHeight: '16px'
    }
  }
}
