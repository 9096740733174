import { useState } from 'react'
import http from 'services/http'

export const useGetProductManual = () => {
  const [isLoading, setIsLoading] = useState(false)

  const getManual = async (codigoProduto: number) => {
    setIsLoading(true)
    const manual = await getProductManual(codigoProduto)
    setIsLoading(false)
    return manual
  }

  return { getManual, isLoading }
}

export const getProductManual = async (codigoProduto: number) => {
  const response = await http.get<string>('cliente/manual_proprietario', { codigoProduto })

  if (response.ok && response.data) {
    return response.data
  }

  return ''
}
