import { useState } from 'react'
import * as React from 'react'

import { Box, Text, InputProps, FlexProps, LayoutProps } from '@chakra-ui/react'

type InputFrameProps = LayoutProps &
  FlexProps & {
    label: string
    children: React.ReactElement<InputProps> | React.ReactElement
  }

export const InputFrame = ({ label, children, ...rest }: InputFrameProps) => {
  const [isInvalid, setIsInvalid] = useState(false)
  const [isRequired, setIsRequired] = useState(false)

  React.useEffect(() => {
    if (children.props.name) {
      const inputEl = document.getElementsByName(children.props.name)
      const isAriaInvalid = JSON.parse(inputEl[0].getAttribute('aria-invalid') || 'false')
      const isAriaRequired = JSON.parse(inputEl[0].getAttribute('aria-required') || 'false')
      setIsInvalid(isAriaInvalid)
      setIsRequired(isAriaRequired)
    }
  }, [children])

  return (
    <Box
      h={10}
      borderWidth={1}
      borderRadius="4px"
      borderColor={isInvalid ? 'crimson' : 'dark.800'}
      position="relative"
      mt="16px"
      px={1}
      py={1}
      {...rest}
    >
      <Text
        position="absolute"
        color={isInvalid ? 'crimson' : 'dark.800'}
        top="-12px"
        left={4}
        variant="medium-bold"
        backgroundColor="white"
        px={2}
        zIndex="docked"
      >
        {label}{' '}
        {isRequired && (
          <Text color="red.500" fontWeight="medium" role="presentation" aria-hidden="true" as="span">
            *
          </Text>
        )}
      </Text>
      {children.type['id'] === 'Input' ? React.cloneElement(children, { variant: 'framed' }) : children}
    </Box>
  )
}
