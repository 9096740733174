import {
  Box,
  HStack,
  Image,
  Link,
  Icon,
  Center,
  useBreakpointValue,
  Tooltip,
  Text,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'
import { IoMenu, IoNotifications } from 'react-icons/io5'

import logoDesktop from 'assets/images/logo_template.png'
import logoDesktop2x from 'assets/images/logo_template@2x.png'
import logoMobile from 'assets/images/logo_mobile.png'
import logoMobile2x from 'assets/images/logo_mobile@2x.png'

import { NavigationLink } from 'components/navigation-link'
import { Visible } from 'components/visible'
import { useResponsiveImage } from 'hooks/use-responsive-image'

import { FiChevronDown } from 'react-icons/fi'

export type HeaderProps = {
  activeProductName: string
  onMenuClick?: () => void
  onNotificationsClick?: () => void
  onLogoutClick?: () => void
  onActiveProductClick?: () => void
  showProductsMenu: boolean
}

export const Header = ({
  onMenuClick,
  onLogoutClick,
  onNotificationsClick,
  activeProductName,
  onActiveProductClick,
  showProductsMenu
}: HeaderProps) => {
  const hideFaqLink = useBreakpointValue({ base: false, xl: true })
  const hideProductSelecion = useBreakpointValue({ base: false, xl: true })
  const logo = useResponsiveImage({
    mobile: { src: logoMobile, srcSet: `${logoMobile} 1x, ${logoMobile2x} 2x`, boxSize: 100 },
    desktop: { src: logoDesktop, srcSet: `${logoDesktop} 1x, ${logoDesktop2x} 2x`, boxSize: 180 }
  })
  const showMenu = useBreakpointValue({ base: false, md: true })

  return (
    <HStack
      role="menubar"
      as="header"
      position="fixed"
      h="100px"
      top={0}
      left={0}
      right={0}
      w="100vw"
      boxShadow="0px 29px 23px #fff"
      bg="white"
      zIndex="docked"
    >
      <Image src={logo?.src} srcSet={logo?.srcSet} w={`${logo?.boxSize}px`} alignSelf="start" />
      <HStack spacing="30px" flex={1} justify="center">
        <Visible when={showMenu}>
          <>
            <Link variant="dark" fontWeight={400} to="/" as={NavigationLink}>
              Início
            </Link>
            <Link variant="dark" fontWeight={400} to="/financeiro" as={NavigationLink}>
              Fluxo financeiro
            </Link>
            <Link variant="dark" fontWeight={400} to="/assistencia-tecnica" as={NavigationLink}>
              Assistência técnica
            </Link>
            <Visible when={hideFaqLink}>
              <Link variant="dark" fontWeight={400} to="/faq" as={NavigationLink}>
                Dúvidas frequentes
              </Link>
            </Visible>
            {showProductsMenu && (
              <Visible when={hideProductSelecion}>
                <HStack
                  bg="dark.300"
                  pl="18px"
                  pr="10px"
                  py="4px"
                  borderRadius="full"
                  transition="background-color .3s"
                  cursor="pointer"
                  _hover={{ bg: 'dark.400' }}
                  onClick={onActiveProductClick}
                >
                  <Text variant="bold">{activeProductName}</Text> <FiChevronDown />
                </HStack>
              </Visible>
            )}
          </>
        </Visible>
      </HStack>
      <Box>
        <Tooltip label="Sair do portal" fontSize="md" openDelay={500}>
          <Link color="red.600" _hover={{ color: 'red.500' }} fontWeight={400} onClick={onLogoutClick}>
            Sair
          </Link>
        </Tooltip>
      </Box>
      {/* <NotificationsButton onNotificationsClick={onNotificationsClick} /> */}
      <Box px="30px" role="button" aria-label="Abrir menu">
        <Center
          w="45px"
          h="45px"
          borderRadius="full"
          bg="dark.900"
          _hover={{
            bg: 'dark.800',
            cursor: 'pointer'
          }}
          _active={{
            bg: 'dark.700'
          }}
          onClick={() => onMenuClick && onMenuClick()}
        >
          <Icon boxSize="18px" as={IoMenu} color="primary.brand" />
        </Center>
      </Box>
    </HStack>
  )
}

function NotificationsButton({ onNotificationsClick }) {
  return (
    <Popover placement="auto-end">
      <PopoverTrigger>
        <Box pl="30px" role="button" aria-label="Abrir notificações">
          <Center
            boxSize="45px"
            rounded="full"
            bg="silver.400"
            _hover={{
              bg: 'dark.300',
              cursor: 'pointer'
            }}
            _active={{
              bg: 'dark.400'
            }}
            onClick={() => onNotificationsClick && onNotificationsClick()}
          >
            <Icon boxSize="18px" as={IoNotifications} color="dark.800" />
          </Center>
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverHeader>Notificações</PopoverHeader>
        <PopoverBody minH={'300px'}>
          <Box rounded="5px" py={2} px={4} bg="silver.300">
            <Text>Convocação de AGI</Text>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
