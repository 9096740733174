import {
  Button,
  FormControl,
  Text,
  Input,
  Stack,
  Box,
  Link,
  FormErrorMessage,
  Checkbox,
  Alert,
  AlertIcon,
  AlertDescription
} from '@chakra-ui/react'

import { Link as RouterLink } from 'react-router-dom'

import { useForm } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'hooks/use-auth'
import { InputFrame } from 'components/input-frame'
import { useState } from 'react'

interface IFormInputs {
  login: string
  senha: string
}

const schema = yup.object().shape({
  login: yup.string().min(9).max(15).label('Login'),
  senha: yup.string().length(6).label('Senha')
})

export const LoginForm = () => {
  const { Login } = useAuth()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema)
  })

  const submitForm = async ({ login, senha }: IFormInputs) => {
    setLoading(true)
    const { hasError, errorMessage } = await Login(login, senha)
    if (hasError) {
      setError(errorMessage || 'Erro ao tentar fazer login. Por favor, tente novamente em alguns minutos.')
      setLoading(false)
    }
  }

  return (
    <Box w="80%">
      <Text fontSize="2xl" mb={4}>
        Login
      </Text>
      <form onSubmit={handleSubmit(submitForm)}>
        {error && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            <AlertDescription fontWeight={300}>{error}</AlertDescription>
          </Alert>
        )}
        <Stack>
          <FormControl isInvalid={!!errors.login} isRequired>
            <InputFrame label="Login">
              <Input {...register('login')} name="login" autoComplete="username" type="text" required />
            </InputFrame>
            <FormErrorMessage>{errors.login?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.senha} isRequired>
            <InputFrame label="Senha">
              <Input
                name="senha"
                {...register('senha')}
                type="password"
                autoComplete="current-password"
                required
                colorScheme="red"
              />
            </InputFrame>
            <FormErrorMessage>{errors.senha?.message}</FormErrorMessage>
          </FormControl>
          <FormControl pt={2}>
            <Checkbox name="lembrar">
              <Text fontSize="sm">Lembrar meu login</Text>
            </Checkbox>
          </FormControl>

          <Box pt={15}>
            <Button type="submit" color="gray.900" w={200} isLoading={loading}>
              Entrar
            </Button>
          </Box>
          <Stack pt={20}>
            <Link variant="dark" as={RouterLink} to="/login/forgot-password" fontSize="sm" pb={5}>
              Esqueci minha senha
            </Link>
            <Link variant="dark" as={RouterLink} to="/login/register" fontSize="sm">
              Primeiro acesso? <b>Criar login</b>
            </Link>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}
