import http from 'services/http'
import { useQuery } from 'react-query'
import { FinancialSummaryDto } from 'services/http/dtos/financial-summary.dto'

export const useFinancialSummary = (codigoVenda: number) => {
  return useQuery<FinancialSummaryDto>(['financial-summary', codigoVenda], () => getFunancialSummary(codigoVenda))
}

const getFunancialSummary = async (codigoVenda: number) => {
  const response = await http.get<FinancialSummaryDto>('/cliente/resumo_financeiro', {
    codigoVenda
  })

  if (response.ok && response.data) {
    return response.data
  }

  throw new Error(response.problem || 'Erro ao executar ação')
}
