export default {
  parts: ['field'],
  baseStyle: {
    fontWeight: 300,
    _placeholder: {
      color: 'dark.500'
    }
  },
  variants: {
    outline: {
      field: {
        position: 'relative',
        borderColor: 'gray.200',
        bg: 'silver.300'
      }
    },
    framed: {
      field: {
        border: 'none',
        px: 2,
        fontWeight: 300,
        zIndex: 'base',
        borderRadius: '4px',
        height: 'full'
      }
    }
  },
  defaultProps: {
    focusBorderColor: 'gray.800'
  }
}
