import { Table, Th, Tbody, Td, Text, Center, Spinner, Tag, Thead, Tr } from '@chakra-ui/react'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'
import { TechnicalSupportDto } from 'services/http/dtos/technical-support.dto'
import { formatDate } from 'utils/format-date'

export const SupportsTable = ({ data }: { data: TechnicalSupportDto[] }) => {
  const isStatusDone = (status: string) => status.toLowerCase() === 'fechado'
  const { isDesktop } = useDeviceScreenSize()

  return (
    <Table mt="30px" size="sm">
      <Thead>
        <Tr>
          <Th>#</Th>
          <Th>Etapa</Th>
          <Th>Status</Th>

          {isDesktop && <Th>Abertura</Th>}
          {isDesktop && <Th>Finalização</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {data ? (
          data.length > 0 ? (
            data.map(({ id, etapa, status, dataAbertura, dataFinalizacao }) => (
              <Tr key={`${id}`}>
                <Td>#{id}</Td>
                <Td>{etapa}</Td>
                <Td>
                  <Tag colorScheme={isStatusDone(status) ? 'green' : 'blue'}>
                    {isStatusDone(status) ? 'Concluído' : 'Aberto'}
                  </Tag>
                </Td>

                {isDesktop && <Td>{formatDate(Date.parse(dataAbertura))}</Td>}
                {isDesktop && <Td>{dataFinalizacao ? formatDate(Date.parse(dataFinalizacao)) : ''}</Td>}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={5}>
                <Center>
                  <Text>Não há casos a serem exibidos.</Text>
                </Center>
              </Td>
            </Tr>
          )
        ) : (
          <Tr>
            <Td colSpan={5}>
              <Center py={6}>
                <Spinner />
              </Center>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  )
}
