import { Box, Flex, useDisclosure, Text, List, ListItem, HStack } from '@chakra-ui/react'
import { DrawerMenu } from 'components/drawer-menu'
import { Footer } from 'components/footer'
import { Header } from 'components/header'
import { ModalDialog } from 'components/modal-dialog'
import { useAuth } from 'hooks/use-auth'

import { Outlet } from 'react-router-dom'

import { useApplicationStore } from 'stores/application-store'
import { HiOutlineCheckCircle } from 'react-icons/hi'
import { ProductDto } from 'services/http/dtos/user-info.dto'
import { mixpanel } from 'services/mixpanel'
import { events } from 'constants/events'

export const MainPage = () => {
  const { isOpen: isMenuOpen, onClose: onMenuClose, onOpen: onMenuOpen } = useDisclosure()
  const { Logout } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isProductsDialogOpen, onOpen: onProductsDialogOpen, onClose: onProductsDialogClose } = useDisclosure()
  const activeProduct = useApplicationStore((state) => state.activeProduct)
  const products = useApplicationStore((state) => state.products)
  const setActiveProduct = useApplicationStore((state) => state.setActiveProduct)

  const askLogout = () => {
    onOpen()
  }

  const onLogout = () => {
    Logout()
    onClose()
    mixpanel.track(events.SIGN_OUT)
  }

  const openDrawerMenu = () => {
    onMenuOpen()
    mixpanel.track(events.OPEN_SIDE_MENU)
  }

  const handleActiveProduct = (product: ProductDto) => {
    setActiveProduct(product)
    onProductsDialogClose()
  }

  const formatProductName = (product: ProductDto) => `${product.descricaoProduto} (${product.identificador.trim()})`

  return (
    <>
      <ModalDialog
        buttonText="Sair"
        isOpen={isOpen}
        onButtonClick={onLogout}
        onClose={onClose}
        buttonOptions={{ colorScheme: 'red' }}
      >
        <Text>Deseja realmente sair do potral?</Text>
      </ModalDialog>
      <ModalDialog
        title="Contratos"
        isOpen={isProductsDialogOpen}
        onClose={onProductsDialogClose}
        onButtonClick={onProductsDialogClose}
        buttonText="Ok"
      >
        <Box pb="30px">
          <Text>
            Selecione o contrato ativo. Parte das informações exibidas no portal serão referentes a este contrato.
          </Text>
          <List pt="22px">
            {products.map((product) => (
              <ListItem
                key={product.identificador}
                _hover={{ bg: 'dark.300' }}
                py="6px"
                px="8px"
                borderRadius="4px"
                cursor="pointer"
                bgGradient={
                  activeProduct.identificador === product.identificador
                    ? 'linear(to-r, primary.light, primary.yellow)'
                    : null
                }
                onClick={() => handleActiveProduct(product)}
              >
                <HStack>
                  <HiOutlineCheckCircle
                    visibility={activeProduct.identificador === product.identificador ? 'visible' : 'hidden'}
                  />
                  <Text>{formatProductName(product)}</Text>
                </HStack>
              </ListItem>
            ))}
          </List>
        </Box>
      </ModalDialog>
      <Header
        onMenuClick={openDrawerMenu}
        onLogoutClick={askLogout}
        activeProductName={`${activeProduct.descricaoProduto} (${activeProduct.identificador.trim()})`}
        onActiveProductClick={() => onProductsDialogOpen()}
        showProductsMenu={products.length > 1}
      />

      <DrawerMenu
        isOpen={isMenuOpen}
        onClose={onMenuClose}
        onLogoutClick={askLogout}
        activeProductName={formatProductName(activeProduct)}
        onActiveProductClick={() => {
          onProductsDialogOpen()
          onMenuClose()
        }}
        showProductsMenu={products.length > 1}
      />
      <Flex maxW="full" minH={'90vh'} pt="140px" as="main" px={{ base: '30px', xl: 0 }} overflow="hidden">
        <Box w={{ base: 'full', xl: '1280px' }} mx="auto">
          <Outlet />
        </Box>
      </Flex>
      <Box pt="60px">
        <Footer />
      </Box>
    </>
  )
}
