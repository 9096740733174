import { VStack, HStack, Skeleton, SkeletonCircle } from '@chakra-ui/react'

export const PaymentSummaryChartSkeleton = () => {
  return (
    <HStack bg="dark.300" borderRadius="full" h={227} px="30px" justifyContent="space-evenly" w="full">
      <VStack>
        <Skeleton h={3} w={20} />
        <Skeleton h={3} w={20} />
        <Skeleton h={3} w={20} />
      </VStack>
      <SkeletonCircle size="170px" />
    </HStack>
  )
}
