import { AuthenticationResponseDto } from 'services/http/dtos/authtentications.dto'
import * as yup from 'yup'

const schema = yup.object().shape({
  token: yup.string().required(),
  profile: yup.object().shape({
    id: yup.number().required(),
    nome: yup.string().required()
  }),
  products: yup.array().min(1)
})

export const validateAuthResponse = (user: AuthenticationResponseDto) => schema.isValidSync(user)
