import React from 'react'
import ReactDOM from 'react-dom/client'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import 'i18n/yup.locale.pt-br'

import App from './app'
import reportWebVitals from './reportWebVitals'

import { ChakraProvider, CSSReset } from '@chakra-ui/react'
import theme from 'theme'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <CSSReset />
      <App />
    </ChakraProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
