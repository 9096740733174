import http from 'services/http'
import { useQuery } from 'react-query'
import { BoletoClienteType } from 'services/http/dtos/available-bills.dto'

export const useAvailableBills = () => useQuery('available-bills', listAvailableBills)

const listAvailableBills = async (): Promise<BoletoClienteType[]> => {
  const response = await http.get<BoletoClienteType[]>('cliente/listar_boletos')

  if (response.data) {
    return response.data
  }

  throw new Error(response.problem || 'Erro ao executar ação')
}
