import { createContext, useEffect, useState } from 'react'
import http, { setAuthHeader } from 'services/http'
import { AuthenticationResponseDto } from 'services/http/dtos/authtentications.dto'
import { ApiError } from 'services/http/dtos/error.dto'
import { useApplicationStore } from 'stores/application-store'
import * as Sentry from '@sentry/browser'
import { useNavigate } from 'react-router-dom'
import { validateAuthResponse } from 'validators/auth-response-validator'
import { mixpanel } from 'services/mixpanel'
import { events } from 'constants/events'
import { UserDataDto } from 'services/http/dtos/user-info.dto'
import Bowser from 'bowser'

const USER_STORES_KEY = 'login-info'

export interface AuthContextData {
  authenticated: boolean
  user: AuthenticationResponseDto
  loading: boolean
  Login(login: string, senha: string): Promise<LoginResponse>
  Logout(): Promise<void>
}

export interface LoginResponse {
  data: AuthenticationResponseDto
  hasError: boolean
  errorMessage: string
}

export const AuthContext = createContext<AuthContextData>({} as AuthContextData)

type AuthContextProps = {
  children: React.ReactNode
}

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [user, setUser] = useState<AuthenticationResponseDto | null>(null)
  const setProducts = useApplicationStore((state) => state.setProdutcs)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const storedUser = localStorage.getItem(USER_STORES_KEY)
    if (storedUser) {
      const credentials = JSON.parse(storedUser) as AuthenticationResponseDto
      if (validateAuthResponse(credentials)) {
        configureUserData(credentials)
      }
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function configureUserData(userData: AuthenticationResponseDto): void {
    setAuthHeader(userData.token)
    setProducts(userData.products)
    setUser(userData)
    configureIntegrations(userData)
  }

  function configureIntegrations(userData: UserDataDto) {
    const { id, ...basicInfo } = userData.profile
    const products = userData.products.map((p) => p.descricaoProduto)
    Sentry.setUser({ ...basicInfo, id: `${id}` })
    mixpanel.identify(userData.profile.id.toString())
    mixpanel.register_once({
      device_type: 'web',
      device_platform: Bowser.getParser(window.navigator.userAgent).getBrowserName()
    })
    mixpanel.people.union('Produtos', products)
    mixpanel.set_group('Produtos', products)
    mixpanel.track(events.SIGN_IN)
  }

  async function Login(login: string, senha: string): Promise<LoginResponse> {
    const response = await http.post<AuthenticationResponseDto, ApiError>('/auth', { login, senha })

    if (response.ok) {
      const { data } = response
      localStorage.setItem(USER_STORES_KEY, JSON.stringify(data))
      configureUserData(data)
      navigate('/')

      return { data, hasError: false, errorMessage: null }
    }

    return { data: null, hasError: true, errorMessage: (response.data as ApiError)?.message }
  }

  async function Logout(): Promise<void> {
    localStorage.clear()
    setUser(null)
    setAuthHeader(null)
    navigate('/login')
  }

  return (
    <AuthContext.Provider value={{ authenticated: !!user, user, Login, Logout, loading }}>
      {children}
    </AuthContext.Provider>
  )
}
