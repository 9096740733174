import { DeleteIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Flex,
  ListItem,
  OrderedList,
  Stack,
  Textarea,
  UnorderedList,
  VStack,
  Text,
  Wrap,
  WrapItem,
  Image,
  Heading
} from '@chakra-ui/react'
import { InputFrame } from 'components/input-frame'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'
import { useNewTechnicalSupport } from 'hooks/use-supports'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useApplicationStore } from 'stores/application-store'

interface SupportFormProps {
  onSuccess: () => void
}

export const SupportForm = ({ onSuccess }: SupportFormProps) => {
  const { isMobile } = useDeviceScreenSize()
  const [files, setFiles] = useState<FileWithPreview[]>([])
  const descriptionRef = useRef(null)
  const { createNew, isLoading } = useNewTechnicalSupport()
  const { codigoVenda } = useApplicationStore((state) => state.activeProduct!)

  const onDropFiles = useCallback(
    (acceptedFiles) => {
      setFiles((added) => {
        files.map((file) => URL.revokeObjectURL(file.preview))
        const newFiles = [...added, ...acceptedFiles].map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        )
        return newFiles
      })
    },
    [files]
  )

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: { 'image/png': ['.png'], 'image/jpeg': ['.jpeg', '.jpg'] },
    maxFiles: 5,
    onDrop: onDropFiles
  })

  const validate = async () => {
    const descriptionField = descriptionRef.current
    if (descriptionField.value.length < 15) {
      alert('Atenção: O campo Descrição é obrigatório de deve conter pelo menos 15 caracteres.')
      return
    }

    try {
      await createNew(descriptionField.value, codigoVenda, files)
      onSuccess()
      descriptionField.value = ''
      setFiles([])
    } catch (error) {
      alert('Erro: Ops, parece que tivemos um problema ao enviar o seu pedido.')
    }
  }

  useEffect(() => {
    if (fileRejections.length > 0) alert(`You have ${fileRejections.length} files rejected`)
  }, [fileRejections])

  useEffect(
    () => () => {
      files.map((file) => URL.revokeObjectURL(file.preview))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <Heading mt="80px" textAlign={'right'} variant={isMobile ? 'h4-bold' : 'h3-bold'}>
        Nova solicitação de assistência técnica
      </Heading>
      <Stack direction={{ base: 'column', xl: 'row' }} pt="30px" spacing="30px">
        <Box flex={1}>
          <Text>
            Aqui você pode solicitar o atendimento de assistência técnica caso esteja enfrentando dificuldades com o seu
            imóvel.
          </Text>
          <br />
          <Text>Para que possamos lhe atender da melhor maneira, fique atento(a) aos seguintes detalhes:</Text>
          <br />
          <OrderedList fontWeight={300} spacing="5px" pt="5px">
            <ListItem>
              <Text>Descreva de forma clara o problema</Text>
            </ListItem>
            <ListItem>
              <Text>Informe o local do problema</Text>
            </ListItem>
            <ListItem>
              <Text>Se possível, envie fotos mostrando exatamente o local que necessita de reparo</Text>
            </ListItem>
          </OrderedList>
          <br />
          <Text>Caso precise de ajuda, entre em contato:</Text>
          <UnorderedList listStyleType="none" spacing="5px" pt="5px">
            <ListItem as={Text}>Telefone: (011) 3676-1157</ListItem>
          </UnorderedList>
        </Box>
        <VStack flex={2} spacing="30px" h={files.length > 0 ? '470px' : 'auto'}>
          <InputFrame label="Descrição" width="full" h="120px" p={4}>
            <Textarea
              placeholder="Descreva aqui o problema e a área que necessida de assistência."
              ref={descriptionRef}
              name="descricao"
              h="100%"
              rows={1}
              variant="unstyled"
              resize="none"
              fontWeight={300}
              isRequired
              maxLength={300}
              minLength={15}
            />
          </InputFrame>
          <InputFrame label="Fotos" flex={1} width="full" p={6}>
            <>
              <Center
                {...getRootProps({ className: 'dropzone' })}
                h="100px"
                borderWidth={1}
                borderColor="dark.500"
                bg="dark.300"
                borderStyle="dashed"
                borderRadius="full"
              >
                <input {...getInputProps()} />

                <Text color="dark.700" fontWeight={300}>
                  Arraste as fotos aqui, ou clique para selecionar. Apenas fotos são permitidas.
                </Text>
              </Center>
              <UploadFilesPreview
                files={files}
                onDelete={(file) => setFiles((files) => files.filter((curr) => curr !== file))}
              />
            </>
          </InputFrame>
        </VStack>
      </Stack>
      <Flex justifyContent="flex-end" pt="30px">
        <Button w="120px" onClick={validate} isLoading={isLoading}>
          Enviar
        </Button>
      </Flex>
    </>
  )
}

type FileWithPreview = File & { preview: string }

const UploadFilesPreview = ({
  files,
  onDelete
}: {
  files: FileWithPreview[]
  onDelete: (file: FileWithPreview) => void
}) => {
  return (
    <Wrap spacing={6} mt="18px" mx={6}>
      {files.map((file, index) => (
        <WrapItem
          position="relative"
          key={index}
          p={1}
          borderRadius={2}
          borderWidth={1}
          borderColor="dark.400"
          h="100px"
          w="100px"
        >
          <Center
            cursor="pointer"
            aria-label="Remover anexo"
            boxSize="30px"
            bg="red.500"
            _hover={{
              bg: 'red.600'
            }}
            position="absolute"
            borderRadius="full"
            borderColor="red.600"
            borderWidth={1}
            top={-3}
            right={-3}
            zIndex="docked"
            onClick={() => onDelete(file)}
          >
            <DeleteIcon color="white" boxSize="14px" />
          </Center>
          <Image src={file.preview} boxSize="100%" objectFit="cover" />
        </WrapItem>
      ))}
    </Wrap>
  )
}
