import { useQuery } from 'react-query'
import http from 'services/http'
import { InstallmentDto } from 'services/http/dtos/installment.dto'

export const useContractInstallments = (codigoVenda: number) =>
  useQuery(['contract-installments', codigoVenda], () => getContractInstallments(codigoVenda))

const getContractInstallments = async (codigoVenda: number): Promise<InstallmentDto[]> => {
  const response = await http.get<InstallmentDto[]>('cliente/parcelas_do_contrato', {
    codigoVenda
  })

  if (response.ok && response.data) {
    return response.data
  }

  throw new Error(response.problem || 'Erro ao executar ação')
}
