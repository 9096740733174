import pageBg from 'assets/images/page_bg.png'

export default {
  global: {
    'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
      {
        WebkitBoxShadow: '0 0 0 30px white inset !important;'
      },
    html: {
      scrollBehavior: 'smooth'
    },
    body: {
      color: 'dark.900',
      bgImage: `url(${pageBg})`,
      bgRepeat: 'no-repeat',
      bgPosition: 'right bottom -400px',
      bgSize: 'contain',
      bgAttachment: 'fixed'
    },
    '#root': {
      h: '100vh'
    },

    '.fade-enter': {
      opacity: 0,
      zIndex: 1
    },

    '.fade-enter.fade-enter-active': {
      opacity: 1,
      transition: 'opacity 250ms ease-in'
    }
  }
}
