import {
  Text,
  Image,
  VStack,
  HStack,
  List,
  ListItem,
  Link,
  Icon,
  Divider,
  useMediaQuery,
  Stack
} from '@chakra-ui/react'

import logoVinx from 'assets/images/vinx_logo_cinza.png'
import logoVinxVendas from 'assets/images/logo_vinx_vendas.png'
import logoVinxVendas2x from 'assets/images/logo_vinx_vendas@2x.png'
import logoVinxLab from 'assets/images/logo_vinx_lab.png'
import logoVinxLab2x from 'assets/images/logo_vinx_lab@2x.png'

import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'

export const Footer = () => {
  const [isLargerThan1280] = useMediaQuery('(min-width: 1280px)')
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)')
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  return (
    <VStack w="full" bgColor="#F0F1F2" py="60px" px="30px">
      <Stack
        alignItems="start"
        direction={{ base: 'column', md: 'row' }}
        spacing="60px"
        w={{ base: 'full', xl: '1280px' }}
      >
        <VStack flex={1} alignItems="start" spacing="30px">
          <Image src={logoVinx} w="86px" />
          <Text variant="medium">
            Incorporamos e construímos para atender as necessidades e expectativas dos nossos clientes, melhorando
            continuamente nossos produtos através da capacitação contínua dos colaboradores, e da adoção de práticas
            sustentáveis de gestão.
          </Text>
          <HStack>
            <Link
              w="30px"
              h="30px"
              bg="primary.brand"
              borderRadius="full"
              aria-label="Linkedin"
              display="flex"
              alignItems="center"
              justifyContent="center"
              isExternal
              href="https://www.linkedin.com/company/vinxconstrutora"
            >
              <Icon boxSize="16px" as={FaLinkedinIn} color="dark.800" />
            </Link>
            <Link
              w="30px"
              h="30px"
              bg="primary.brand"
              borderRadius="full"
              aria-label="Instagram"
              display="flex"
              alignItems="center"
              justifyContent="center"
              isExternal
              href="https://www.instagram.com/vinxconstrutora/"
            >
              <Icon boxSize="16px" as={FaInstagram} color="dark.800" />
            </Link>

            <Link
              w="30px"
              h="30px"
              bg="primary.brand"
              borderRadius="full"
              aria-label="Facebook"
              display="flex"
              alignItems="center"
              justifyContent="center"
              isExternal
              href="https://www.facebook.com/vinxconstrutora/"
            >
              <Icon boxSize="16px" as={FaFacebookF} color="dark.800" />
            </Link>
            <Link
              w="30px"
              h="30px"
              bg="primary.brand"
              borderRadius="full"
              aria-label="Twitter"
              display="flex"
              alignItems="center"
              justifyContent="center"
              isExternal
              href="https://twitter.com/vinxConstrutora"
            >
              <Icon boxSize="16px" as={FaTwitter} color="dark.800" />
            </Link>
          </HStack>
        </VStack>
        <HStack flex={{ base: 1, md: 2, lg: 3 }} spacing="10px" pt={4}>
          {isLargerThan1280 && (
            <VStack spacing="30px" alignItems="flex-start" flex={1}>
              <Text variant="medium-bold" color="dark.900" opacity="50%">
                Empreendimentos
              </Text>
              <List spacing="10px">
                <ListItem>
                  <Link fontSize="14px" isExternal href="https://vinx.com.br/empreendimentos">
                    Breve lançamento
                  </Link>
                </ListItem>
                <ListItem>
                  <Link fontSize="14px" isExternal href="https://vinx.com.br/empreendimentos">
                    Lançamento
                  </Link>
                </ListItem>
                <ListItem>
                  <Link fontSize="14px" isExternal href="https://vinx.com.br/empreendimentos">
                    Em construção
                  </Link>
                </ListItem>
                <ListItem>
                  <Link fontSize="14px" isExternal href="https://vinx.com.br/empreendimentos">
                    Prontos para morar
                  </Link>
                </ListItem>
              </List>
            </VStack>
          )}

          {isLargerThan992 && (
            <VStack spacing="30px" alignItems="flex-start" flex={1}>
              <Text variant="medium-bold" color="dark.900" opacity="50%">
                Navegue
              </Text>
              <List spacing="10px">
                <ListItem>
                  <Link fontSize="14px" isExternal href="https://vinx.com.br/grupo">
                    VINX
                  </Link>
                </ListItem>
                <ListItem>
                  <Link fontSize="14px" isExternal href="https://vinx.com.br/minha-casa-minha-vida">
                    Casa verde e amarela
                  </Link>
                </ListItem>
                <ListItem>
                  <Link fontSize="14px" isExternal href="https://vinx.com.br/fale-conosco">
                    Fale conosco
                  </Link>
                </ListItem>
                <ListItem>
                  <Link fontSize="14px" isExternal href="https://vinx.com.br/venda">
                    Venda seu terreno
                  </Link>
                </ListItem>
              </List>
            </VStack>
          )}
          {isLargerThan768 && (
            <VStack spacing="30px" alignItems="flex-start" flex={1}>
              <Text variant="medium-bold" color="dark.900" opacity="50%">
                &nbsp;
              </Text>
              <List spacing="10px">
                <ListItem>
                  <Link fontSize="14px">Blog</Link>
                </ListItem>
                <ListItem>
                  <Link fontSize="14px">Imprensa</Link>
                </ListItem>
                <ListItem>
                  <Link fontSize="14px">Trabalhe conosco</Link>
                </ListItem>
                <ListItem>
                  <Link fontSize="14px">Parceiros e corretores</Link>
                </ListItem>
              </List>
            </VStack>
          )}

          <VStack spacing="30px" flex={1} alignItems={{ base: 'flex-start', md: 'flex-end' }}>
            <Text variant="medium-bold" color="dark.900" opacity="50%">
              Empresas do grupo
            </Text>
            <Stack
              direction={{ base: 'row', md: 'column' }}
              alignItems={{ base: 'center', md: 'flex-end' }}
              spacing="30px"
            >
              <Image src={logoVinxVendas} srcSet={`${logoVinxVendas} 1x, ${logoVinxVendas2x} 2x`} h="36px" />
              <Image src={logoVinxLab} srcSet={`${logoVinxLab} 1x, ${logoVinxLab2x} 2x`} h="36px" />
            </Stack>
          </VStack>
        </HStack>
      </Stack>
      <Divider />
      <Text variant="medium">
        Av. Francisco Matarazzo, 1752, 9º andar - Água Branca - CEP 05001-200 - São Paulo - SP | © 2021. Todos os
        direitos reservados - <Link>Política de privacidade</Link> - CNPJ 26.212.764/0001-34
      </Text>
    </VStack>
  )
}
