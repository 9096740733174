import { ApiResponse } from 'apisauce'
import { useState } from 'react'
import http from 'services/http'

export const useGetInvoicePDFBase64 = () => {
  const [data, setData] = useState<string>()
  const [error, setError] = useState<string>()

  const base64InvoicePDF = async (codigoBanco: number, seuNumero: number) => {
    const response = await generateInvoice(codigoBanco, seuNumero)
    if (response.ok && response.data) {
      setData(response.data)
      return response.data
    }

    setError(error)
  }

  return { base64InvoicePDF, error, data }
}

const generateInvoice = async (codigoBanco: number, seuNumero: number): Promise<ApiResponse<string>> => {
  const response = await http.get<string>('cliente/gerar_boleto_pdf', {
    codigoBanco,
    seuNumero
  })

  return response
}
