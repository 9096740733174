export default {
  parts: ['label', 'control', 'icon'],
  baseStyle: {
    label: {
      fontSize: '16px',
      fontWeight: 300
    },
    control: {
      borderColor: 'dark.800',
      _checked: {
        borderColor: 'dark.800',
        bg: 'transparent',
        bgGradient: 'linear(to-r, primary.yellow, primary.light)',
        _hover: {
          bg: 'transparent',
          bgGradient: 'linear(to-l, primary.yellow, primary.light)',
          borderColor: 'dark.900'
        }
      },
      _disabled: {
        bg: 'dark.400',
        borderColor: 'dark.400'
      }
    },
    icon: {
      color: 'dark.900'
    }
  }
}
