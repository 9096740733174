const gradientButton = {
  bg: 'primary.olive',
  bgGradient: 'linear(to-r, primary.yellow, primary.light)',
  ':first-of-type': {
    zIndex: 'base'
  },
  _before: {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    w: 'full',
    h: 'full',
    bgGradient: 'linear(to-r, primary.light, primary.yellow)',
    zIndex: 'hide',
    opacity: 0,
    transition: 'opacity .6s'
  },
  _hover: {
    bgGradient: 'linear(to-r, primary.yellow, primary.light)',
    _before: {
      opacity: 1
    }
  },
  _active: {
    bg: 'primary.olive'
  }
}

export default {
  defaultProps: {
    size: 'md',
    variant: 'primary'
  },
  sizes: {
    md: {
      h: 10,
      minW: 10,
      fontSize: 'md',
      fontWeight: 400
    },
    sm: {
      h: 45,
      minW: 10,
      fontSize: 'sm',
      fontWeight: 400
    }
  },
  baseStyle: {
    color: 'dark.900',
    borderRadius: 'full'
  },
  variants: {
    primary: {
      ...gradientButton,
      _before: {
        borderRadius: null
      }
    },
    outline: {
      display: 'grid',
      placeItems: 'center',
      position: 'relative',
      borderRadius: 'full',
      border: 0,
      h: '44px',
      borderWidth: '2px',
      borderColor: 'transparent',
      borderStyle: 'solid',
      bgGradient: 'linear(to-r, primary.yellow, primary.light)',
      backgroundClip: 'padding-box',
      ':last-child': {
        zIndex: 'base'
      },
      _after: {
        content: '""',
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        bg: '#fff',
        borderRadius: 'full',
        zIndex: 'hide',
        margin: '2px'
      },
      _before: {
        content: '""',
        position: 'absolute',
        top: '0',
        right: '0',
        left: '0',
        bottom: '0',
        bgGradient: 'linear(to-l, primary.yellow, primary.light)',
        borderRadius: 'full',
        zIndex: 'hide',
        opacity: 0,
        transition: 'opacity .6s'
      },
      _hover: {
        bgGradient: 'linear(to-r, primary.yellow, primary.light)',
        _before: {
          opacity: 1
        },
        backgroundClip: 'padding-box',
        bg: 'transparent'
      },
      _active: {
        bg: 'transparent',
        borderColor: 'primary.brand'
      }
    },
    'square-gradient': {
      ...gradientButton,
      borderRadius: null,
      _before: {
        borderRadius: null
      }
    },
    square: {
      borderRadius: null,
      bg: 'dark.300'
    }
  }
}
