export const frequentQuestions = [
  {
    title: 'Dúvidas sobre Cobrança',
    questions: [
      {
        question: 'Por que o valor do boleto está diferente do valor do contrato?',
        answer:
          'Todas as parcelas são atualizadas monetariamente até a confirmação do pagamento. Essa atualização é feita pelo INCC até a emissão do habite-se e após a emissão do habite-se, a atualização é feita pelo IGPM + 12% a.a.'
      },
      {
        question: 'Não recebi ou perdi o vencimento do boleto, como emito segunda via?',
        answer:
          'Você pode emitir a segunda via do seu boleto pelo portal do cliente ou através de nossos canais de atendimento.'
      },
      {
        question: 'Consigo antecipar parcelas? Tem desconto?',
        answer: 'Sim, é possível. A possibilidade do desconto é para quitação antecipada do saldo devedor.'
      },
      {
        question: 'Como alterar a data de vencimento das minhas parcelas?',
        answer: 'Você pode entrar em contato em um de nossos canais de atendimento.'
      },
      {
        question: 'Onde consigo ver o meu extrato?',
        answer:
          'O extrato pode ser visto no portal do cliente ou ainda solicitado no telefone (11) 3676-1157 ou cobranca@vinx.com.br.'
      }
    ]
  },
  {
    title: 'Dúvidas sobre financiamento',
    questions: [
      {
        question: 'Quando eu assino o contrato de financiamento?',
        answer:
          'Após o lançamento do projeto, o prazo médio para assinar o financiamento é de 6 (seis) meses, podendo ser antecipado ou postergado.'
      },
      {
        question: 'Quando inicia a parcela de financiamento com o banco?',
        answer: 'A parcela de financiamento inicia no mês subsequente a conclusão da obra com o banco.'
      },
      {
        question: 'Quando inicia a cobrança da taxa de evolução de obra? Qual a forma de pagamento?',
        answer:
          'A taxa de evolução de obra inicia no mês seguinte a assinatura do contrato de financiamento e é debitada da conta corrente ativa na Caixa Econômica.'
      },
      {
        question: 'Só posso financiar com a CEF?',
        answer:
          'Quando se trata de projeto por crédito associativo (Casa Verde e Amarela), o financiamento bancário é com a Caixa Econômica no período de obras.'
      },
      {
        question:
          'Quais os custos temos desde a compra até a entrega do imóvel? Haverá algum custo extra na entrega de chaves?',
        answer: `
        Os custos que envolvem o crédito imobiliário são:\n
        i.	ITBI (Imposto de Transmissão de Bens Imóveis), \n
        ii.	Registro | Escritura (valor pago ao cartório de registro do imóvel), \n
        iii.	Taxa de evolução de obra (pagamento mensal a Caixa Econômica), \n
        iv.	Taxa a vista (pagamento feito ao banco no ato da assinatura do contrato de financiamento).\n
        `
      },
      {
        question: '6.	Quando receberei o contrato do banco registrado?',
        answer:
          'O contrato de financiamento registrado pode ser retirado com a assessoria no período de 90 dias após a assinatura.'
      }
    ]
  },
  {
    title: 'Dúvidas sobre contrato',
    questions: [
      {
        question: 'Quando receberei o meu contrato de compra e venda assinado?',
        answer: 'O prazo para o recebimento do contrato de compra e venda digital é de 60 dias após a assinatura.'
      },
      {
        question: 'Como posso obter a planta em DWG?',
        answer: 'A planta disponibilizada é em PDF juntamente com o contrato de compra assinado por todas as partes.'
      },
      {
        question: 'Como funciona o distrato?',
        answer:
          'Antes de partimos para o distrato, você tem opções de negociações conosco. Porém, conforme contrato, o distrato pode ser solicitado pelo cliente através de nosso e-mail (atendimento@vinx.com.br) ou ainda acontecer de forma unilateral com o descumprimento das regras de pagamento e/ou contratuais. Para maiores informações sobre as regras de devolução do valor pago, solicitamos que consulte o seu contrato de compra.'
      }
    ]
  },
  {
    title: 'Dúvidas de obra / entrega do imóvel',
    questions: [
      {
        question: 'Quando iniciam as obras?',
        answer: 'As obras, geralmente, são iniciadas em média em um período de 6 meses pós lançamento.'
      },
      {
        question: 'Quando será a vistoria do imóvel?',
        answer:
          'Você será comunicado sobre o período disponível para a vistoria da sua unidade após a conclusão da obra. Vale lembrar que a vistoria é agendada no período próximo à data do Habite-se, que varia de empreendimento para empreendimento.'
      },
      {
        question: 'Quando será a AGI?',
        answer:
          'A instalação de condomínio é feita após a conclusão da obra, 100% das vistorias aceitas e emissão do habite-se.'
      },
      {
        question: 'Quando receberei as chaves?',
        answer: 'As chaves são entregues após a instalação do condomínio para as unidades aptas.'
      },
      {
        question: 'Assim que eu receber as chaves posso iniciar a reforma?',
        answer:
          'A partir do recebimento das chaves (pós AGI), você poderá iniciar os serviços de medições, decoração, pisos e montagem de armários em sua unidade. Importante: consultar as normas do condomínio com o síndico antes.'
      },
      {
        question: 'Se tiver algum problema no apartamento como aciono a assistência técnica?',
        answer:
          'Entre em contato conosco através de um de nossos canais de atendimento que estaremos prontamente disponíveis para abrirmos o chamado e o engenheiro Vinx ir até a sua unidade.'
      },
      {
        question: 'Como pedir ligação de energia?',
        answer:
          'Após o recebimento de chaves é preciso entrar em contato com a concessionária responsável pela energia na região do imóvel.'
      },
      {
        question: 'Água e gás são individualizados?',
        answer: 'Geralmente não, dependente de empreendimento para empreendimento.'
      },
      { question: 'Quando inicia o pagamento do condomínio?', answer: 'O pagamento do condomínio inicia após a AGI.' },
      {
        question: 'Onde consigo comprar o mesmo material utilizado no apartamento? (tinta, azulejo, etc...)',
        answer:
          'No manual do proprietário, entregue juntamente as chaves, é comunicado os tipos de materiais utilizados e fornecedores.'
      },
      {
        question: 'Quais etapas para entrega do apartamento?',
        answer: `
      •	Venda\t\n
      •	Contrato\t\n
      •	Financiamento imobiliário\t\n
      •	Obras\t\n
      •	Vistoria\t\n
      •	Habite-se\t\n
      •	Quitação Financeira junto a construtora\t\n
      •	Entrega\t\n
      `
      }
    ]
  },
  {
    title: 'Dúvidas gerais',
    questions: [
      {
        question: 'Posso vender o meu imóvel durante a fase de construção?',
        answer:
          'Se não houve o financiamento bancário, é possível fazer cessão de direitos. Caso já tenha assinado o contrato com o banco, é preciso aguardar finalizar a obra e verificar a possibilidade de transferência de dívida com o banco.'
      },
      {
        question: 'Posso trocar a minha unidade?',
        answer:
          'Antes de iniciar o pagamento das parcelas, a troca de unidade pode ser feita com o seu corretor. Após o início dos pagamentos, por gentileza entrar em contato com um de nossos canais de atendimento para analisarmos a possibilidade.'
      },
      {
        question: 'Quais vantagens para quitar o imóvel antes da entrega de chaves?',
        answer: 'Além da possibilidade de desconto, você deixa de pagar a atualização monetária mensal.'
      },
      {
        question: 'Como posso usar o meu FGTS na compra do imóvel?',
        answer:
          'O FGTS pode ser utilizado para compra de imóveis residenciais, sendo condição o comprador não ter outro imóvel em seu nome na mesma cidade onde está adquirindo o imóvel que for utilizar o FGTS ou em cidades limítrofes.'
      },
      {
        question: 'O que é o habite-se',
        answer:
          'É o certificado de conclusão da obra expedido pela Prefeitura, atestando que o empreendimento imobiliário pode ser habitado, emitido no término da obra.'
      },
      {
        question: 'Com quem posso compor renda na compra do imóvel?',
        answer:
          'Não existe obrigatoriedade de grau de parentesco para compor renda no financiamento com a Caixa Econômica, somente quantidade de pessoas que é o máximo é 3 pessoas.'
      },
      {
        question: 'Se tiver restrição em meu nome, posso comprar imóvel?',
        answer: 'Não, é necessário regularizar para submetermos a avaliação do banco.'
      },
      {
        question: 'Se tiver imóvel em meu nome, posso comprar outro no programa Casa verde e amarela (PCVA)?',
        answer:
          'Não, exceto para imóveis quitados, imóveis fora da região onde pretende adquirir e/ou região limítrofe.'
      },
      {
        question: 'O banco financia 100% do valor do imóvel?',
        answer: 'O financiamento é no máximo 80% no PCVA e 90% no SBPE.'
      }
    ]
  }
]
