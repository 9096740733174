import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay
} from '@chakra-ui/react'
import { BarCodePanel } from 'components/installment-payment'
import { useRef } from 'react'

export type InvoiceBarcodeDialogProps = {
  code: string
  isOpen: boolean
  onClose: () => void
}

export const InvoiceBarcodeDialog = ({ code, isOpen, onClose }: InvoiceBarcodeDialogProps) => {
  const cancelRef = useRef(null)

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
        size={'xl'}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Linha digitável
            </AlertDialogHeader>
            <AlertDialogCloseButton />

            <AlertDialogBody py={6}>
              <BarCodePanel code={code} />
            </AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
