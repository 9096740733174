import { ApiResponse } from 'apisauce'
import { useState } from 'react'
import http from 'services/http'

export const useStatementOfPaymentAsPDF = (codigoVenda: number) => {
  const [data, setData] = useState<string>()
  const [error, setError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const getStatementOfPaymentAsPDF = async () => {
    setIsLoading(true)
    const response = await getStatementOfPayment(codigoVenda)
    if (response.ok && response.data) {
      setData(response.data)
      setIsLoading(false)
      return response.data
    }

    setIsLoading(false)
    setError(error)
  }

  return { isLoading, getStatementOfPaymentAsPDF, error, data }
}

const getStatementOfPayment = async (codigoVenda: number): Promise<ApiResponse<string>> => {
  const response = await http.get<string>('cliente/demonstrativo_pagamento', {
    codigoVenda
  })

  return response
}
