import { ReactElement } from 'react'

type RenderWhenProps = {
  when: boolean
  fallback?: () => ReactElement | null
  render: () => ReactElement | null
}

export const RenderWhen = ({ when, fallback = null, render }: RenderWhenProps) =>
  when ? render() : fallback ? fallback() : null
