export default {
  dark: {
    900: '#212121',
    800: '#3A3A3A',
    700: '#47484D',
    600: '#6F7074',
    500: '#A0A5AB',
    400: '#DCDCDC',
    300: '#F5F5F5'
  },
  primary: {
    dark: '#59AA27',
    brand: '#55E100',
    light: '#78F500',
    olive: '#BBE120',
    yellow: '#E7FF00'
  },
  silver: {
    300: '#F7F7F7',
    400: '#F2F3F6'
  },
  background: '#FFFFFF'
}
