import { useMutation } from 'react-query'
import http from 'services/http'
import { ApiError } from 'services/http/dtos/error.dto'
import { LoginOrEmail } from 'services/http/dtos/login-or-email.dto'
import { get } from 'lodash'

export const useRecoveryPassword = () =>
  useMutation((loginOrEmail: LoginOrEmail) =>
    http.post<string, ApiError>('auth/recuperar_credenciais', loginOrEmail).then((response) => {
      if (response.ok) {
        return get(response, ['data', 'mensagem'], 'Sucesso!')
      } else {
        throw response.data
      }
    })
  )
