import { useState } from 'react'
import { Button, Input, Stack, useClipboard, useToast } from '@chakra-ui/react'

export type BillGenerationType = 'pdf' | 'line' | 'print'

type BarCodePanelProps = {
  code?: string
}

export const BarCodePanel = ({ code }: BarCodePanelProps) => {
  const [value] = useState(code || '')
  const { onCopy } = useClipboard(value)
  const toast = useToast()

  return (
    <Stack>
      <Input readOnly value={value} />
      <Button
        aria-label="Copiar linha digitável"
        width="180px"
        fontSize="sm"
        alignSelf="flex-end"
        onClick={() => {
          toast({
            title: 'Copiado!',
            position: 'top',
            description: 'A linha digitável foi copiada para a sua área de transferência.',
            status: 'success',
            isClosable: true
          })
          onCopy()
        }}
      >
        Copiar linha digitável
      </Button>
    </Stack>
  )
}
