import { create } from 'apisauce'
import { AxiosInstance } from 'axios'
import { authInterceptor } from './interceptors/auth.interceptor'

const instance = create({
  baseURL: process.env.REACT_APP_ENDPOINT_API
})

authInterceptor(instance.axiosInstance as AxiosInstance)

const setAuthHeader = (token: string) => instance.setHeader('authorization', token)

export default instance
export { setAuthHeader }
