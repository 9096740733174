import { useState } from 'react'
import {
  Box,
  Button,
  Center,
  Checkbox,
  Heading,
  Icon,
  Spacer,
  Spinner,
  Stack,
  Tag,
  Table,
  TableCaption,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useBreakpointValue,
  Wrap,
  WrapItem,
  Link
} from '@chakra-ui/react'
import { useApplicationStore } from 'stores/application-store'
import { useFinancialSummary } from 'hooks/use-financial-summary'
import { formatMoney } from 'utils/format-money'
import { useContractInstallments } from 'hooks/use-contract-installments'
import { tiposParcelasUau } from 'data/tipos-parcelas-uau.data'
import { formatDate } from 'utils/format-date'
import { parseISO } from 'date-fns'
import { useStatementOfPaymentAsPDF } from 'hooks/use-statement-of-payment'
import { downloadPDF } from 'utils/download-pdf'
import { FinancialSummary } from 'components/financial-summary'
import { FaCloudDownloadAlt } from 'react-icons/fa'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'
import { useTitle } from 'routes/use-title'

export const Finance = () => {
  useTitle('Fluxo financeiro')
  const { codigoVenda } = useApplicationStore((state) => state.activeProduct!)
  const { data: financialSummary } = useFinancialSummary(codigoVenda)
  const { data: installments } = useContractInstallments(codigoVenda)
  const activeProduct = useApplicationStore((state) => state.activeProduct)
  const { getStatementOfPaymentAsPDF, isLoading: isDownloadingStatement } = useStatementOfPaymentAsPDF(
    activeProduct.codigoVenda
  )
  const [showPaid, setShowPaid] = useState(true)
  const [showToPay, setShowToPay] = useState(true)
  const { isMobile } = useDeviceScreenSize()
  const tablePadding = useBreakpointValue({ base: '4px', mg: '16px' })

  return (
    <Stack w="full">
      <Heading variant={isMobile ? 'h3-bold' : 'h2-bold'}>Fluxo financeiro do seu contrato</Heading>
      <Text>Resumo financeiro do seu contrato.</Text>
      <Box h="15px" />
      <FinancialSummary data={financialSummary} />
      <Box h="30px" />
      <Stack h="full" px={5} direction={isMobile ? 'column' : 'row'}>
        <Checkbox mr={4} isChecked={showPaid} onChange={() => setShowPaid((value) => !value)}>
          Parcelas pagas
        </Checkbox>
        <Checkbox isChecked={showToPay} onChange={() => setShowToPay((value) => !value)}>
          Parcelas pendentes
        </Checkbox>
        <Spacer />
        <Box>
          <Button
            isLoading={isDownloadingStatement}
            onClick={() =>
              getStatementOfPaymentAsPDF().then((value) => value && downloadPDF(value, 'Demonstrativo de Pagamento'))
            }
          >
            <Icon as={FaCloudDownloadAlt} />
            &nbsp; Demonstrativo de Pagamentos
          </Button>
        </Box>
      </Stack>
      <Box h="20px" />

      {isMobile && (
        <>
          <Text variant="bold">Tipos de parcelas:</Text>
          <Wrap>
            {installments &&
              installments
                .map((inst) => inst.tipo)
                .filter((inst, index, self) => self.indexOf(inst) == index)
                .map((tipo) => (
                  <WrapItem key={tipo}>
                    <Tag borderRadius="full" colorScheme="gray">{`(${tipo}) ${tiposParcelasUau[tipo]}`}</Tag>
                  </WrapItem>
                ))}
          </Wrap>
        </>
      )}

      <Box>
        <Table size={isMobile ? 'sm' : 'md'}>
          <TableCaption>
            <Text fontSize="xs" color="gray.500">
              Consulto a evolução do INCC:{' '}
              <Link isExternal href="https://www.debit.com.br/tabelas/tabela-completa.php?indice=incc">
                https://www.debit.com.br/tabelas/tabela-completa.php?indice=incc
              </Link>
            </Text>
            <Box mt={6}>
              <Text as="i" fontSize="xs">
                * Os valores exibidos são válidos para {formatDate(new Date())}, podendo sofrer alterações diárias.{' '}
                <br />
                ** Para os clientes que assinaram o financiamento bancário, o valor da parcela de financiamento é
                repassado pelo banco mensalmente.
              </Text>
            </Box>
          </TableCaption>
          <Thead>
            <Tr>
              <Th px={tablePadding}>Tipo</Th>
              <Th px={tablePadding}>Vencimento</Th>
              <Th px={tablePadding}>Status</Th>
              <Th px={tablePadding} isNumeric>
                Valor
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {installments ? (
              installments
                .filter((inst) => (inst.pago ? showPaid : true))
                .filter((inst) => (!inst.pago ? showToPay : true))
                .map((inst) => (
                  <Tr key={`${inst.valor}-${inst.data_venc}`}>
                    <Td px={tablePadding}>{isMobile ? inst.tipo : tiposParcelasUau[inst.tipo]}</Td>
                    <Td px={tablePadding}>{formatDate(parseISO(inst.data_venc))}</Td>
                    <Td px={tablePadding}>
                      {isMobile ? (
                        <Tag colorScheme={inst.pago ? 'green' : 'gray'}>{inst.pago ? 'PG' : 'NP'}</Tag>
                      ) : (
                        <Tag colorScheme={inst.pago ? 'green' : 'gray'}>{inst.pago ? 'Pago' : 'Pendente'}</Tag>
                      )}
                    </Td>
                    <Td px={tablePadding} isNumeric>
                      {formatMoney(inst.valor)}
                    </Td>
                  </Tr>
                ))
            ) : (
              <Tr>
                <Td colSpan={5}>
                  <Center py={6}>
                    <Spinner />
                  </Center>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Stack>
  )
}
