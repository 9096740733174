import { Stack, VStack, Heading, Text, Image } from '@chakra-ui/react'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'
import { useResponsiveImage, createSet } from 'hooks/use-responsive-image'
import ImageBanner from 'assets/images/support_image_banner.png'
import ImageBanner2x from 'assets/images/support_image_banner@2x.png'
import ImageBannerSmall from 'assets/images/support_banner_small_image.png'
import ImageBannerSmall2x from 'assets/images/support_banner_small_image@2x.png'

export const SupportPageHeader = () => {
  const { isMobile, isDesktop } = useDeviceScreenSize()

  const imageBanner = useResponsiveImage({
    mobile: { ...createSet(ImageBannerSmall, ImageBannerSmall2x), boxSize: 190 },
    desktop: { ...createSet(ImageBanner, ImageBanner2x), boxSize: 290 }
  })

  return (
    <Stack justify="flex-end" align="center" direction={isMobile ? 'column' : 'row'} spacing={isMobile ? '30px' : 0}>
      {isMobile && (
        <Image src={imageBanner.src} srcSet={imageBanner.srcSet} w={`${imageBanner.boxSize}px`} objectFit="contain" />
      )}
      <VStack align={isMobile ? 'center' : 'flex-end'} pr={isMobile ? 0 : '30px'} spacing={isMobile ? '30px' : 0}>
        <Heading variant={isMobile ? 'h3-bold' : 'h2-bold'}>Assistência técnica</Heading>
        <Text maxW="680px" align={isMobile ? 'justify' : 'right'}>
          A Vinx se preocupa com cada detalhe da sua expriência. O nosso compromisso em repensar futuros se expressa,
          também, em nossa atenção aos detalhes. E é para ajudar você com esses detalhes, que temos um equipe técnica de
          primeira, treinada para atender você da melhor maneira possível.
        </Text>
      </VStack>

      {isDesktop && (
        <Image
          src={imageBanner.src}
          srcSet={imageBanner.srcSet}
          boxSize={`${imageBanner.boxSize}px`}
          objectFit="contain"
        />
      )}
    </Stack>
  )
}
