import { Flex, Text, CircularProgress, Grid, useBreakpointValue } from '@chakra-ui/react'

export interface StatisticProps {
  title: string
  value: number
  progressValue: number
  color?: string
  showChart?: boolean
  formatter?: (value: number) => string
}

export const Statistic = ({
  title,
  value,
  progressValue,
  color = 'blue.500',
  showChart = true,
  formatter
}: StatisticProps) => {
  const chartSize = useBreakpointValue({ base: '30px', lg: '40px' })

  return (
    <Grid
      templateAreas={{
        base: `'title chart' 'label chart'`
      }}
      bg="dark.300"
      borderRadius="full"
      h={{ base: '80px', lg: '104px' }}
      alignItems="stretch"
      overflow="hidden"
      px="30px"
    >
      <Flex gridArea="title" align="center" justifyContent="flex-start">
        <Text variant="regular" color="dark.700">
          {title}
        </Text>
      </Flex>
      <Flex gridArea="label" alignItems="flex-start" justifyContent="flex-start">
        <Text fontSize="22px" fontWeight="regular" lineHeight={1}>
          {formatter ? formatter(value) : value}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="flex-end" gridArea="chart">
        {showChart && <CircularProgress value={progressValue} capIsRound color={color} size={chartSize} />}
      </Flex>
    </Grid>
  )
}
