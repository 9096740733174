import ProtectedRoute from 'components/protected-route'
import { Route, Routes } from 'react-router-dom'
import { MainPage } from 'pages/main'
import { Login } from 'pages/login'
import { ForgotPasswordForm } from 'components/forgot-password-form'
import { LoginForm } from 'components/login-form'
import { RegisterForm } from 'components/register-form'
import { Dashboard } from 'pages/dashboard/dashboard'
import { Faq } from 'pages/faq'
import { Finance } from 'pages/finance'
import { OwnersManual, UnavailableManual } from 'pages/owners-manual'
import { Support } from 'pages/support'

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/login/*" element={<Login />}>
          <Route path="" element={<LoginForm />} />
          <Route path="register" element={<RegisterForm />} />
          <Route path="forgot-password" element={<ForgotPasswordForm />} />
        </Route>
        <Route
          path="/"
          element={
            <ProtectedRoute redirectTo="/login">
              <MainPage />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<Dashboard />} />
          <Route path="/manual-proprietario" element={<OwnersManual />} />
          <Route path="/financeiro" element={<Finance />} />
          <Route path="/manual-ausente" element={<UnavailableManual />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/assistencia-tecnica" element={<Support />} />
        </Route>
      </Routes>
    </>
  )
}

export default AppRoutes
