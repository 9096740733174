import { Box, Heading, VStack, Image, Text, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import UnavailableManualImage from 'assets/images/unavailable_manual@2x.png'

export const SupportUnavailable = () => (
  <VStack maxW="786px" m="0 auto" p="50px" spacing="30px">
    <Heading mb={8}>Assistência técnica indisponível</Heading>
    <Image src={UnavailableManualImage} w="440px" />
    <Box>
      <Text lineHeight="28px">
        Ainda não é possível solicitar assistência técnica!
        <br />
        <br />
      </Text>
      <Text>
        Você vai poder fazer isso, assim que o seu apê estiver pronto e as chaves estiverem com você. Se precisar, você
        pode falar com a gente pelos números (11) 4118-9156 ou (11) 4118-9157, ou pelo e-mail: atendimento@vinx.com.br.
      </Text>
    </Box>
    <Link pt="60px" as={RouterLink} to="/">
      Voltar ao início
    </Link>
  </VStack>
)
