import { SupportInfo } from './components/support-info'
import { Center, Spinner, useDisclosure } from '@chakra-ui/react'

import { useTechnicalSupportCount, useTecnicalSupport } from 'hooks/use-supports'
import { SupportForm } from './components/support-form'
import { isEmpty } from 'utils/is-empty'
import { SupportUnavailable } from './components/unavailable-support'
import { SupportPageHeader } from './components/header'
import { SupportsTable } from './components/supports-table'
import { SuccessModal } from './components/success-modal'
import { useTitle } from 'routes/use-title'
import { useProductsSupportEnabled } from 'hooks/use-products-support-enabled'

export const Support = () => {
  useTitle('Assistência Técnica')
  const { data, refetch } = useTecnicalSupport()
  const { isOpen: isSuccessModalOpen, onClose: onCloseSuccessModal, onOpen: onOpenModalSuccess } = useDisclosure()
  const { data: productsEnabled, isLoading: isProductsEnabledLoading } = useProductsSupportEnabled()
  const { data: supportsCount, isLoading: isSupportsCountLoading } = useTechnicalSupportCount()

  const onSuccess = () => {
    onOpenModalSuccess()
    refetch()
  }

  if (isProductsEnabledLoading || isSupportsCountLoading) {
    return (
      <Center h={'350px'}>
        <Spinner />
      </Center>
    )
  }

  if (isEmpty(productsEnabled)) {
    return <SupportUnavailable />
  }

  if (supportsCount > 0) {
    return <SupportInfo />
  }

  return (
    <>
      <SuccessModal isOpen={isSuccessModalOpen} onClose={onCloseSuccessModal} />
      <SupportPageHeader />
      <SupportsTable data={data} />
      <SupportForm onSuccess={onSuccess} />
    </>
  )
}
