import typograpy from 'theme/foundations/typography'

export default {
  variants: {
    simple: {
      th: {
        fontFamily: typograpy.fonts.body,
        color: 'dark.800',
        borderColor: 'silver.300',
        py: 7
      },
      td: {
        fontFamily: typograpy.fonts.body,
        borderColor: 'dark.300',
        fontWeight: 300
      },
      caption: {
        fontFamily: typograpy.fonts.body,
        textAlign: 'left',
        color: 'dark.800',
        my: 6
      }
    }
  }
}
