import { NavLink, NavLinkProps } from 'react-router-dom'
import colors from 'theme/foundations/colors'

export type NavigationLinkProps = NavLinkProps

export const NavigationLink = ({ children, ...rest }: NavigationLinkProps) => (
  <NavLink {...rest} style={({ isActive }) => ({ color: isActive ? colors.primary.brand : colors.dark[800] })}>
    {children}
  </NavLink>
)
