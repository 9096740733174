import { useState } from 'react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  Input,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'

import { Link as RouterLink } from 'react-router-dom'
import { useRecoveryPassword } from 'hooks/use-recover-password'
import { useForm } from 'react-hook-form'
import { ApiError } from 'services/http/dtos/error.dto'
import { InputFrame } from 'components/input-frame'
import { useTitle } from 'routes/use-title'
import { mixpanel } from 'services/mixpanel'
import { events } from 'constants/events'

interface IFormInputs {
  loginOrEmail: string
}

export const ForgotPasswordForm = () => {
  useTitle('Esqueci minha senha')
  const [showResult, setShowResult] = useState(false)
  const { register, handleSubmit, setValue } = useForm<IFormInputs>({})
  const recovery = useRecoveryPassword()

  const submitForm = async (value: IFormInputs) => {
    mixpanel.track(events.RECOVER_PASSWORD)
    const loginOrEmail = value.loginOrEmail.includes('@')
      ? { email: value.loginOrEmail, login: '' }
      : { email: '', login: value.loginOrEmail }

    setShowResult(false)

    try {
      await recovery.mutateAsync(loginOrEmail)
      setValue('loginOrEmail', '')
      setShowResult(true)
    } catch (error) {
      setShowResult(true)
    }
  }

  return (
    <Box w="80%">
      <Text fontSize="2xl" mb={4}>
        Recuperar senha
      </Text>
      <form onSubmit={handleSubmit(submitForm)}>
        {showResult && (
          <Alert status={recovery.isError ? 'error' : 'success'} mb={4}>
            <AlertIcon />
            <AlertDescription>
              {recovery.isError ? (recovery.error as ApiError).message : recovery.data}
            </AlertDescription>
          </Alert>
        )}
        <Stack>
          <FormControl isRequired>
            <InputFrame label="Informe seu login ou e-mail de cadastro">
              <Input name="loginOrEmail" type="text" {...register('loginOrEmail')} required />
            </InputFrame>
          </FormControl>

          <Box pt={15}>
            <Button type="submit" color="gray.900" w={200} isLoading={recovery.isLoading}>
              Recuperar senha!
            </Button>
          </Box>
          <Stack pt={20}>
            <Link variant="dark" as={RouterLink} to="/login" fontSize="sm" pb={5}>
              Já tenho cadastro, <b>Entrar</b>
            </Link>
            <Link variant="dark" as={RouterLink} to="/login/register" fontSize="sm">
              Primeiro acesso? <b>Criar login</b>
            </Link>
          </Stack>
        </Stack>
      </form>
    </Box>
  )
}
