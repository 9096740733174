import { Center, Image, Button, Badge, useBreakpointValue, LayoutProps } from '@chakra-ui/react'

import PaymentImage from 'assets/images/shotcut_payment_image.png'
import PaymentImage2x from 'assets/images/shotcut_payment_image@2x.png'

import StageImage from 'assets/images/shotcut_stage_image.png'
import StageImage2x from 'assets/images/shotcut_stage_image@2x.png'

import ManualImage from 'assets/images/shotcut_manual_image.png'
import ManualImage2x from 'assets/images/shotcut_manual_image@2x.png'

import SupportImage2x from 'assets/images/shotcut_support_image@2x.png'
import SupportImage from 'assets/images/shotcut_support_image.png'

import PaymentImageCompact from 'assets/images/shotcut_payment_image_compact.png'
import PaymentImageCompact2x from 'assets/images/shotcut_payment_image_compact@2x.png'

import StageImageCompact from 'assets/images/shotcut_stage_image_compact.png'
import StageImageCompact2x from 'assets/images/shotcut_stage_image_compact@2x.png'

import ManualImageCompact from 'assets/images/shotcut_manual_image_compact.png'
import ManualImageCompact2x from 'assets/images/shotcut_manual_image_compact@2x.png'

import SupportImageCompact from 'assets/images/shotcut_support_image_compact.png'
import SupportImageCompact2x from 'assets/images/shotcut_support_image_compact@2x.png'

import { emptyFn } from 'utils/empty-fn'
import { RenderWhen } from 'components/render-when'
import { useDeviceScreenSize } from 'hooks/use-device-sscreen-size'

type Background = keyof typeof ShotcutBackgrounds['large']
type BackgroundSizes = keyof typeof ShotcutBackgrounds

export const ShotcutBackgrounds = {
  large: {
    payment: { src: PaymentImage, srcSet: `${PaymentImage} 1x, ${PaymentImage2x} 2x` },
    stage: { src: StageImage, srcSet: `${StageImage} 1x, ${StageImage2x} 2x` },
    support: { src: SupportImage, srcSet: `${SupportImage} 1x, ${SupportImage2x} 2x` },
    manual: { src: ManualImage, srcSet: `${ManualImage} 1x, ${ManualImage2x} 2x` }
  },
  small: {
    payment: { src: PaymentImageCompact, srcSet: `${PaymentImageCompact} 1x, ${PaymentImageCompact2x} 2x` },
    stage: { src: StageImageCompact, srcSet: `${StageImageCompact} 1x, ${StageImageCompact2x} 2x` },
    support: { src: SupportImageCompact, srcSet: `${SupportImageCompact} 1x, ${SupportImageCompact2x} 2x` },
    manual: { src: ManualImageCompact, srcSet: `${ManualImageCompact} 1x, ${ManualImageCompact2x} 2x` }
  }
}

export const ShotcutSizes = {
  large: { width: 350, height: 250 },
  small: { width: 350, height: 120 }
}

export type ShotCutProps = LayoutProps & {
  buttonTitle: string
  background: Background
  onLinkClick?: () => void
  isLoading?: boolean
  buttonWidth?: string | number
  buttonBadge?: number
  isButtonActive?: boolean
}

export const ShotcutBox = ({
  buttonTitle: buttonTitle,
  background: illustrationType,
  onLinkClick = emptyFn,
  buttonWidth = 'auto',
  buttonBadge,
  isLoading = false,
  isButtonActive = false,
  ...rest
}: ShotCutProps) => {
  const imageSize = useBreakpointValue<BackgroundSizes>({ base: 'small', xl: 'large' })
  const buttonY = useBreakpointValue({ base: '-3px', xl: '50px' })
  const { isMobile, isDesktop } = useDeviceScreenSize()

  return (
    <Center
      position="relative"
      {...rest}
      h={isMobile ? 'auto' : `${ShotcutSizes[imageSize ?? 'small'].height}px`}
      w={isMobile ? 'auto' : `${ShotcutSizes[imageSize ?? 'small'].width}px`}
      role="group"
    >
      <RenderWhen
        when={isDesktop}
        render={() => (
          <Image
            onClick={onLinkClick}
            cursor="pointer"
            filter="grayscale(100%)"
            src={ShotcutBackgrounds[imageSize ?? 'small'][illustrationType].src}
            srcSet={ShotcutBackgrounds[imageSize ?? 'small'][illustrationType].srcSet}
            transition=".6s"
            _groupHover={{
              filter: 'none'
            }}
          />
        )}
      />

      <Button
        role="group"
        variant={isMobile ? (isButtonActive ? 'square-gradient' : 'square') : 'primary'}
        size="sm"
        position={isMobile ? 'relative' : 'absolute'}
        bottom={buttonY}
        left="0"
        onClick={onLinkClick}
        w={isMobile ? 'full' : buttonWidth}
        isLoading={isLoading}
      >
        {buttonTitle}
        {buttonBadge > 0 && (
          <Badge
            position="absolute"
            top={-3}
            right={2}
            bg="red.500"
            color="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
            w={6}
            h={6}
            borderRadius="full"
          >
            {buttonBadge}
          </Badge>
        )}
      </Button>
    </Center>
  )
}
