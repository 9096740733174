import { extendTheme } from '@chakra-ui/react'

import shadows from './foundations/shadows'
import colors from './foundations/colors'
import styles from './styles'
import typography from './foundations/typography'

import Button from './components/button'
import Input from './components/input'
import Table from './components/table'
import Alert from './components/alert'
import Heading from './components/heading'
import Text from './components/text'
import Checkbox from './components/checkbox'
import Link from './components/link'
import Textarea from './components/textarea'

export const AppTheme = {
  styles,
  shadows,
  colors,
  ...typography,
  components: {
    Button,
    Input,
    Table,
    Alert,
    Heading,
    Text,
    Checkbox,
    Link,
    Textarea
  }
}

export const ThemeType = typeof AppTheme

export default extendTheme(AppTheme)
