/* eslint-disable prettier/prettier */
export const tiposParcelasUau: {[key: string]: string} = {
  '0': 'Seguro',
  '1': 'Custas',
  '2': 'Acerto final',
  '3': 'Anuais',
  '4': 'Intermediaria',
  '5': 'Acerto Mensal',
  '6': 'Ato',
  '7': 'Periodicidade',
  '8': 'FGTS',
  'A': 'Resíduo Agrup.',
  'B': 'Balão',
  'C': 'Chave',
  'E': 'Entrada',
  'F': 'Financiamento',
  'I': 'Isolada',
  'M': 'Mensais',
  'P': 'Parcela',
  'R': 'Resíduo',
  'S': 'Sinal',
  'SM': 'Semestrais',
  'U': 'Substituição',
  'UN': 'Única'
}
