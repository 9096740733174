import { Box, HStack, Skeleton, SkeletonText, Stack, Wrap, WrapItem } from '@chakra-ui/react'

export const InvoiceDetailsSkeleton = () => (
  <Box px="40px" py="20px" borderRadius="4px" borderWidth="1px" borderColor="dark.300" h="300px">
    <Skeleton w="140px" h={4} />
    <Wrap spacing="100px" pt="25px">
      <WrapItem>
        <Stack>
          <SkeletonText noOfLines={2} w="50px" />
        </Stack>
      </WrapItem>
      <WrapItem>
        <Stack>
          <SkeletonText noOfLines={2} w="50px" />
        </Stack>
      </WrapItem>
      <WrapItem>
        <Stack>
          <SkeletonText noOfLines={2} w="50px" />
        </Stack>
      </WrapItem>
    </Wrap>
    <Stack mt="40px">
      <SkeletonText noOfLines={1} w="120px" />
      <SkeletonText noOfLines={1} w="480px" />
    </Stack>
    <HStack mt="90px">
      <Skeleton w="200px" h="45px" borderRadius="full" />
      <Skeleton w="200px" h="45px" borderRadius="full" />
      <Skeleton w="200px" h="45px" borderRadius="full" />
    </HStack>
  </Box>
)
