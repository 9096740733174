import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'

type ModalDialogProps = ModalProps & {
  title?: string
  buttonText?: string
  onButtonClick?: () => void
  buttonOptions?: ButtonProps
  isLoading?: boolean
}

export const ModalDialog = ({
  title,
  buttonText = 'Ok',
  onButtonClick,
  buttonOptions = {},
  isLoading = false,
  onClose,
  children,
  ...rest
}: ModalDialogProps) => {
  return (
    <Modal {...rest} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={onButtonClick || onClose}
            px={6}
            isLoading={isLoading}
            {...buttonOptions}
            variant={buttonOptions.colorScheme ? 'solid' : null}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
