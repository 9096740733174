import { Box, Center, Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'

import loginBg from 'assets/images/login_bg.jpeg'

export const Login = () => {
  return (
    <Flex
      justify="flex-start"
      h="full"
      w="full "
      bgImage={`url(${loginBg})`}
      bgSize="cover"
      bgPosition="center"
      pl="70px"
    >
      <Center w="524px" h="full" bg="white">
        <Box my={10} w="full">
          <Center py={5}>
            <Outlet />
          </Center>
        </Box>
      </Center>
    </Flex>
  )
}
