import { Center, Spinner } from '@chakra-ui/react'
import { useAuth } from 'hooks/use-auth'
import { Navigate } from 'react-router-dom'

interface AppRouteProps {
  children: JSX.Element
  redirectTo: string
}

const ProtectedRoute = function ({ children, redirectTo }: AppRouteProps) {
  const { authenticated, loading } = useAuth()

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return authenticated ? children : <Navigate to={redirectTo} />
}

export default ProtectedRoute
